

.container-slider {
  max-width: 700px;
  height: 500px;
  margin: 100px auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 700px){
  .container-slider {
    margin: 100px 10px 0;
  }
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #D9D9D9;
  border:2px solid #f2f2f2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 12px;
  height: 20px;
  pointer-events: none;
}
.prev {
  top: 52%;
  left: -3%;
  transform: translateY(-60%);
}
.next {
  top: 52%;
  right: -3%;
  transform: translateY(-60%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}


.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}