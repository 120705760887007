@font-face {
    font-family: font1;
    src: url("../fonts/font2.ttf");
}

.accordion-name{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
color: #000;
}



.accordions{
    margin-top: 30px;
    background-color: red;
}

.products .slider__rightBtn{

        position: absolute;
        top: 40%;
        z-index: 100;
        left: 94%;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
}

.products .slider__leftBtn{
    
        position: absolute;
        top: 40%;
        z-index: 100;
        left: 0%;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
   
}


.Products{
    position: relative;
    padding: 0;
    margin: 0;
    margin-top: 100px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @media (max-width:767px){
        height: 120%;
    }
    @media (max-width:500px){
        height: 110%;
    }
    @media (max-width:450px){
        height: 100%;
    }
    .wrapper{
        padding: 70px 15vw;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        @media (max-width:1629px){
            padding: 70px 15vw;
        }
        @media (max-width:1555px){
            padding:70px 15vw;
        }
        @media (max-width:1250px){
            padding: 70px 10vw;
        }
        @media (max-width:1000px){
            padding: 70px 8vw;
        }
        @media (max-width:870px){
            padding: 70px 7vw;
        }
        @media (max-width:767px){
            padding: 70px 100px;
        }
        @media (max-width:700px){
            padding: 70px 80px;
        }
        @media (max-width:560px){
            padding: 70px 50px;
        }
        @media (max-width:470px){
            padding: 70px 30px;
        }

        .show-more{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;            
            .btn{
                border: solid 1px;
                background-color: black;
                color: white;
                // transition: 0.2s linear;
                position: relative;   
                padding: 10px 15px;
                box-shadow: none;
                display: none;
                &:hover{
                    background-color: white;
                    color: black;
                }  
                &:active{
                    transform: scale(0.97);
                }           
                &:nth-child(1){
                    display: block;
                }            
            }
        }        

        .menu-catalog{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0 0 15px 3px rgb(209, 209, 209);
            border-radius: 10px;
            overflow: hidden;
            @media (max-width:600px){
                width: 100%;
                border-radius: 5px;
            }            
            .catalog-menu{
                // border: solid 1px;
                width: 10vw;
                position: relative;
                transition: 0.5s;
                cursor: pointer;                
                @media (max-width:1400px){
                    width: 13vw;
                }
                @media (max-width:900px){
                    width: 16vw;
                }
                @media (max-width:767px){
                    width: 14vw;
                }                             
                @media (max-width:500px){
                    width: 16vw;                    
                }
                @media (max-width:450px){
                    width: 18vw;                    
                }               
                input[type="radio"]{
                    display: none;
                }
                input:checked ~ label{
                    transition: 0.5s;
                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 7px;
                        left: 0;
                        bottom: 0;
                        background-color: rgb(255, 174, 0);
                        @media (max-width:600px){
                            height: 4px;
                        }
                    }
                }
                label{
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    padding: 30px 40px;
                    img{
                        width: 100%;
                        height: 100%;                        
                    }
                    @media (max-width:1150px){
                        padding: 30px 40px;
                    }
                    @media (max-width:1100px){
                        padding: 30px 35px;
                    }
                    @media (max-width:950px){
                        padding: 30px;
                    }
                    @media (max-width:767px){
                        padding: 20px;
                    }
                    @media (max-width:600px){
                        padding: 15px 20px;
                    }
                    @media (max-width:500px){
                        padding: 15px;
                    }
                    @media (max-width:420px){
                        padding: 10px;
                    }
                }
                &:hover{
                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 7px;
                        left: 0;
                        bottom: 0;
                        background-color: gold;
                        @media (max-width:600px){
                            height: 4px;
                        }
                    }
                }
            }
        }

        .tip-catalog{
            margin-top: 40px;
            width: 100%;
            display: flex;
            // align-items: center;
            justify-content: center;
            // overflow: hidden;
            padding: 10px 30px;

            
            @media (max-width:650px){
                padding: 10px 40px;
            }
            @media (max-width:500px){
                padding: 10px 0px;                
            }
            @media (max-width:390px){
                padding: 10px 0;
                margin: 5px 0;
            }
            .tip{
                cursor: pointer;
                text-align: center;
                position: relative;
                input[type="radio"]{
                    display: none;
                }
                label{
                    cursor: pointer;
                    width: 100%;
                    height: 100%; 
                    padding: 0 20px;  
                    display: flex;
                    align-items: center;
                    justify-content: center;    
                    &:hover{
                        &::before{
                            content: "";
                            position: absolute;
                            color: #E80000;
                            width: 12px;
                            height: 12px;
                            bottom: -13px;
                            background-color: rgb(255, 206, 101);
                            border-radius: 50%;
                            @media (max-width:650px){
                                width: 7px;
                                height: 7px;
                            }
                        }
                    }
                    @media (max-width:800px){
                        padding: 0 10px;
                    }
                    @media (max-width:600px){
                        padding: 0 5px;
                        border-left: solid 1px rgb(208, 208, 208);            
                    }
                    h6{
                        margin: 0;
                        font-family: "Inter";                        
                    }
                    p{
                        text-align: center;
                        font-size: 20px;
                        font-weight: 400;
                        color: rgb(156, 156, 156);
                        @media (max-width:900px){
                            font-size: 16px;
                        }
                        @media (max-width:767px){
                            font-size: 16px;
                        }
                        @media (max-width:650px){
                            font-size: 14px;
                        }
                        @media (max-width:500px){
                            font-size: 12px;
                        }
                        @media (max-width:400px){
                            font-size: 10px;
                        }
                    }
                }
                input:checked ~ label{
                    transition: 0.5s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p{
                        color: black;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        bottom: -13px;
                        background-color: rgb(255, 174, 0);
                        border-radius: 50%;
                        @media (max-width:650px){
                            width: 12px;
                            height: 12px;
                            bottom: -13px;
                        }
                    }
                }
            }
        }
        .best-catalog{
            margin-top: -10px;
        }

        .all-buy{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 70px;
            .btn{
                border-radius: 0;
                border: solid 1px black;
                color: white;
                padding: 10px 40px;
                background-color: black;
                &:hover{
                    background-color: white;
                    color: black;
                }
            }
            @media (max-width:767px){
                width: 100%;                   
            }
            
        }      
        .products{
            margin-top: 70px;            
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0;     
            position: relative;
            @media (max-width:767px){
                display: flex;
                // grid-template-columns: repeat(2, 1fr);                
            } 
            @media (max-width:620px){
                margin-top: 0px;
            } 
            .prod{                
                padding: 10px;
                margin-top: 10px;
                // transform: translate(-500%);
                display: none;
                // border: solid 1px;
                @media (max-width:767px){
                    width: 50%;                   
                }
                .body{
                    padding: 20px 40px;
                    transition: 0.3s;
                    @media (max-width:1400px){
                        padding: 20px;
                    }
                    @media (max-width:800px){
                        padding: 10px;
                    }                    
                    &:hover{
                        box-shadow: 0 0 30px 10px rgb(231, 231, 231);
                        background-color: white;
                        border-radius: 10px;
                    }
                    .prod-names{
                        @media (max-width:767px){
                            display: none;
                        }  
                    }
                    .res-price{
                        font-size: 12px;
                        margin: 0;
                        @media (min-width:767.1px){
                            display: none;
                        } 
                    }
                    .res-name{
                        font-size: 14px;
                        margin-top: 10px;
                        font-weight: 600;
                        @media (min-width:767.1px){
                            display: none;
                        }
                        margin-bottom: -15px;
                    }
                    .img-list{                        
                        height: 250px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img{
                            position: absolute;
                            max-width: 100%;
                            max-height: 100%;
                        }
                        @media (max-width:1100px){
                            height: 200px;
                        }
                        @media (max-width:900px){
                            height: 150px;
                        }
                        @media (max-width:410px){                                
                            height: 100px;                             
                        }
                    }
                    .title{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 50px;
                        @media (max-width:1400px){
                            margin-top: 20px;
                        }
                        .fa-heart-o{
                            font-size: 27px;
                            cursor: pointer;
                            @media (max-width:1000px){                                
                                font-size: 20px;                              
                            }
                            @media (max-width:410px){                                
                                font-size: 16px;                              
                            } 
                        }
                        .fa-heart{
                            display: none;
                            font-size: 27px;
                            cursor: pointer;
                            color: #E80000;
                            // -webkit-text-stroke: 2px black; 
                            @media (max-width:1000px){                                
                                font-size: 20px;                              
                            }      
                            @media (max-width:410px){                                
                                font-size: 16px;                              
                            } 
                            
                            &:hover{
                                width: 100%;
                                height: 100%;
                                -webkit-text-stroke: 2px black
                            }
                        }
                        .checkbox[type="checkbox"]{
                            display: none;
                        }
                        .checkbox:checked ~ label .fa-heart-o{
                            display: none;
                        }
                        .checkbox:checked ~ label .fa-heart{
                            display: block;
                        }                       
                        .checkbox:checked ~ .modlab .fa-heart-o{
                            display: none;
                        }
                        .checkbox:checked ~ .modlab .fa-heart{
                            display: block;
                        }
                        .checkbox1[type="checkbox"]{
                            display: none;
                        }
                        .checkbox1:checked ~ label .fa-heart-o{
                            display: none;
                        }
                        .checkbox1:checked ~ label .fa-heart{
                            display: block;
                        }                       
                        .checkbox1:checked ~ .modlab .fa-heart-o{
                            display: none;
                        }
                        .checkbox1:checked ~ .modlab .fa-heart{
                            display: block;
                        }
                        .checkbox12[type="checkbox"]{
                            display: none;
                        }
                        .checkbox12:checked ~ label .fa-heart-o{
                            display: none;
                        }
                        .checkbox12:checked ~ label .fa-heart{
                            display: block;
                        }                       
                        .checkbox12:checked ~ .modlab .fa-heart-o{
                            display: none;
                        }
                        .checkbox12:checked ~ .modlab .fa-heart{
                            display: block;
                        }
                        .checkbox13[type="checkbox"]{
                            display: none;
                        }
                        .checkbox13:checked ~ label .fa-heart-o{
                            display: none;
                        }
                        .checkbox13:checked ~ label .fa-heart{
                            display: block;
                        }                       
                        .checkbox13:checked ~ .modlab .fa-heart-o{
                            display: none;
                        }
                        .checkbox13:checked ~ .modlab .fa-heart{
                            display: block;
                        }
                        p{
                            line-height: 15px;
                            font-size: 15px;
                            @media (max-width:1000px){                                
                                font-size: 13px;
                            }
                            @media (max-width:410px){                                
                                font-size: 11px;                               
                            }
                        }
                        h4{
                            line-height: 10px;
                            font-size: 14px;
                            font-weight: 500;
                            @media (max-width:1000px){                                
                                font-size: 14px;
                                font-weight: 500;
                            }
                            @media (max-width:410px){                                
                                font-size: 12px;                                
                            }
                        }
                    }
                }
            }  
            .middle-main{
                // transform: translate(0);
                // position: absolute;
                // top: 0;
                display: block;
            }  
        }
        .slick-next{       
            right: -40px;       
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: rgb(194, 194, 194); 
            @media (max-width:500px){
                width: 25px;
                height: 25px;
                right: -20px; 
            }
        }
        .slick-next:before{
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;   
            border-radius: 50%;            
            font-weight: 100;            
            color: black;                
            font-size: 35px;
            font-weight: 900; 
            @media (max-width:500px){
                font-size: 25px;
            }       
        } 
        .slick-prev{       
            left: -40px;       
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: rgb(194, 194, 194);
            @media (max-width:500px){
                width: 25px;
                height: 25px;
                left: -20px; 
            }
        }
        .slick-prev:before{
            content: "\f104";
            font-family: FontAwesome;
            font-style: normal;   
            border-radius: 50%;         
            font-weight: 100;            
            color: black;                
            font-size: 35px;
            font-weight: 900; 
            @media (max-width:500px){
                font-size: 25px;
            }       
        }      
        .best-prod::first-letter{
            text-transform: uppercase;
        }
        .best-prod{
            margin-top: -70px;
        }
        .one-product{
            // top: 150px;
            top: 10vh;
            height: 80vh;
            background-color: white;
            position: fixed;
            z-index: 500007;
            width: 70%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow-y: scroll;
            // display: none;
            @media (max-width:1300px){
                width: 80%;
            }
            @media (max-width:767px){
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                padding: 20px;
                top: 7.5vh;
                height: 85vh;
                overflow-y: scroll;
            }
            @media (max-width:450px){
                padding: 30px 0;
            }
            @media (max-width:380px){
                width: 90%;
            }
            .imgs{
                padding: 40px;
                padding-right: 10px;
                @media (max-width:1300px){
                    padding: 20px;
                }
                .body{
                    align-items: center;
                    justify-content: start;
                    .img-list{
                        padding: 10px;
                        .body{
                            border: solid 1px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 350px;
                            position: relative;
                            @media (max-width:1050px){
                                height: 250px;
                            }
                            @media (max-width:450px){
                                height: 200px;
                            }
                            img{
                                height: 100%;
                                max-width: 100%;
                            }
                            .a3D{
                                color: black;
                                font-weight: 500;
                                cursor: pointer;
                                position: absolute;
                                z-index: 10;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0.383);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 0;
                                left: 0;
                                &:hover{
                                    .fa-play{
                                        background-color: black;
                                    }
                                }
                                .fa-play{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    position: absolute;
                                    z-index: -1;
                                    padding-left: 5px;
                                    font-size: 50px;
                                    width: 65px;
                                    height: 65px;
                                    margin: 0;
                                    border-radius: 50%;
                                    background-color: rgba(0, 0, 0, 0.58);
                                }
                            }
                            .sketchfab-embed-wrapper{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                iframe{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                    }
                    .other{                   
                        padding: 10px;
                        width: 8vw;
                        @media (max-width:767px){
                            width: 13vw;
                        }
                        @media (max-width:500px){
                            width: 15vw;
                        }
                        @media (max-width:450px){
                            padding: 5px 0 0 10px;
                        }
                        .body{
                            width: 100%;
                            border: solid 1px;
                            position: relative;
                            height: 100px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: 0.2s;
                            &:hover{
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                            }
                            @media (max-width:1150px){
                                height: 70px;
                            }
                            @media (max-width:767px){
                                height: 100px;
                            }
                            @media (max-width:650px){
                                height: 70px;
                            }
                            @media (max-width:500px){
                                height: 50px;
                            }
                            img{
                                position: absolute; 
                                height: 100%;
                                max-width: 100%;
                            }
                            p{
                                font-size: 30px;
                                @media (max-width:1150px){
                                    font-size: 20px;
                                }
                                @media (max-width:450px){
                                    font-size: 16px;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .texts{
                padding: 40px;
                padding-left: 20px;
                @media (max-width:1300px){
                    padding: 20px;
                }
                // padding-top: 50px;
                .body{
                    // border: solid 1px;
                    padding: 10px;
                    .colors{
                        margin-top: 20px;
                        margin-bottom: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        .cls{
                            border-radius: 50%;
                            outline: none;
                            border: none;
                            width: 30px;
                            height: 30px;
                            margin-right: 20px;
                            transition: 0.2s;
                            cursor: pointer;
                            @media (max-width:650px){
                                width: 25px;
                                height: 25px;
                            }
                            @media (max-width:500px){
                                width: 20px;
                                height: 20px;
                            }
                            &:active{
                                transform: scale(0.95);
                            }                                                   
                        }                       
                    }
                    .btns{
                        margin-top: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        .buy{
                            background-color: black;
                            color: white;
                            border: solid 1px black;
                            outline: none;
                            border-radius: 0;
                            padding: 15px 50px;
                            box-shadow: none;
                            transition: 0.3s;
                            &:active{
                                transform: scale(0.95);
                            }
                            @media (max-width:1050px){
                                padding: 15px 35px;
                            }
                            @media (max-width:500px){
                                padding: 10px 25px;
                            }
                        }
                        .save{                            
                            color: black;
                            border: solid 1px;
                            outline: none;
                            border-radius: 0;                            
                            margin-left: 10px;
                            padding: 0;
                            display: flex; 
                            align-items: center;
                            justify-content: center;
                            box-shadow: none;
                            transition: 0.3s;
                            position: relative;
                            &:active{
                                transform: scale(0.95);
                            }
                            label{
                                width: 100%;
                                height: 100%;
                                cursor: pointer;                                
                                padding: 15px 40px; 
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @media (max-width:1050px){
                                    padding: 15px 30px;
                                }
                                @media (max-width:500px){
                                    padding: 10px 20px;
                                }
                                .fa-heart-o{
                                    font-size: 16px;
                                    cursor: pointer;                                
                                }
                                .fa-heart{
                                    display: none;
                                    font-size: 16px;
                                    cursor: pointer;
                                    color: #E80000;
                                    -webkit-text-stroke: 1px black;                                                     
                                }
                            }
                            .checkbox1[type="checkbox"]{                                
                                display: none;
                            }
                            .checkbox1:checked ~ label .fa-heart-o{
                                display: none;
                            }
                            .checkbox1:checked ~ label .fa-heart{
                                display: block;
                            }    
                            .checkbox13[type="checkbox"]{
                                display: none;
                            }
                            .checkbox13:checked ~ label .fa-heart-o{
                                display: none;
                            }
                            .checkbox13:checked ~ label .fa-heart{
                                display: block;
                            }                       
                            .checkbox13:checked ~ .modlab .fa-heart-o{
                                display: none;
                            }
                            .checkbox13:checked ~ .modlab .fa-heart{
                                display: block;
                            }                        
                        }
                    }
                }
            }
            .fa-times{
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 30px;
                font-weight: 100;
                cursor: pointer;
                @media (max-width:450px){
                    top: 10px;
                }
            }
        }
    }
    .contrast{
        background-color: rgba(0, 0, 0, 0.623);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 500006;
    }    
    .eye-icon{
        cursor: pointer;
        @media (max-width:767px){
            font-size: 20px;
        }
        @media (max-width:420px){
            font-size: 18px;
        }
    }
}

::-webkit-scrollbar{
    width: 0px;
    @media (max-width:767px){
        width: 0px;
    }
}
::-webkit-scrollbar-track{
    background-color:#eee;
}
::-webkit-scrollbar-thumb{
    background-color: rgba(133, 212, 212, 0.781);
    border-radius: 10px;
    @media (max-width:767px){
        background-color: #eee;
    }
}

.color-radio[type="radio"]{
    display: none;
}

.color-radio:checked ~ label{
    filter: brightness(0.7);                        
    border: solid 1px black;
}

.menu-catalogs{
    display: flex;
    justify-content: center;
}

.catalog-menu{
    margin: 0 10px;
}



.Products .tip .product--name{
    font-weight: 300;
    font-size: 20px;
    line-height: 125%;
}

@media (max-width:520px) {
    
    
.Products .tip  .product--name{
    font-size: 8px;   
    // margin: 0 5px;
}

.Products .tip-catalog {
    justify-content: space-between;
}
}
.btn-slide.next, .btn-slide.prev{
    border: none;
    &:hover{
        background-color: rgb(194, 194, 194);
    }
}
.btn-slide.prev{
    @media (max-width:620px){
        top: 57%;       
    }
    @media (max-width:410px){
        top: 58%;
    }
}

@media (max-width:450px) {
       

    .Products .tip  .product--name{
        font-size: 12px;   
    }
}