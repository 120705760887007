.catagory{
    background-image: url("../../Assets/img/classics.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 150px 0 300px 0;
    position: relative;
    margin-top: 100px;
    @media (max-width:520px) {
        padding: 60px 0 60px 0;
        background-size: 130% 100%;
        background-position: center;
    }
    @media (max-width:420px) {
        padding: 50px 0;
    }

    &__list{
        list-style: none;
        position: absolute;
        left: 81%;
        top: 22%;
    }

    &__lists{
        display: flex;
        align-items: center;
        list-style: none;
        position: absolute;
        top: 82%;
        left: 8.5%;
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }
    .catagory{

        &__list{
            left: 75%;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .catagory{

        &__list{
            top: 35%;
            left: 75%;
        }
    }
}