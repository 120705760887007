.header--item{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0.3;
    top: 0;
    left: 0;
    // max-height: 700px;
}

.container{
    // width: 1140px;
    margin: 0 auto;
}
.header--list{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    top: 40px;
    width: 100%;
    z-index: 10;
    max-height: 500px;
    overflow-y: auto;
    border: none;
    li{
        list-style: none;
    }
}
.header{
    background-color: #eee;
    padding: 100px 0 80px 0;
    position: relative;
    @media (max-width:620px) {
        padding: 10px 0;
    }

    &--input{
        margin: 10px 0;
        padding: 5px;
    }

    &--labels{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 200;
        font-size: 36.2px;
        line-height: 125%;
        color:#b6b6b6;
        
    }

    // &--list{
    //     display: flex;
    //     flex-direction: column;
    //     list-style: none;
    //     height: 270px;
    //     width: 230px;
    //     // background-color: #f2f2f2;
    //     overflow-y: scroll;
    //     position: absolute;
    //     top: 45%;
    //     left: 35%;
    //     transition: 1.5s;
    //     border-radius: 10px;
    // }

    &--item{
        margin: 10px 0;
    }

    &--link{
        text-decoration: none;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        margin: 10px 0;
        cursor: pointer;
    }

    &__chair{
        width: 86px;
        height: 86px;
    }

    &__tip{
        text-align: center;
        position: relative;
    }

    &__images{
        width: 500px;
        height: 500px;
        object-fit: contain;
        // border: solid 1px;
        margin-bottom: -80px;
        @media (max-width:767px) {
            display: none;
        }
    }

    &__inputes{
        padding: 5px;
        margin: 5px;
        padding-top: 30px;
        width: 30px;
    }

    &--inputes[type="radio"]{
        display: none;
        cursor: pointer;
    }

    &--inputes{
        margin-left: 10px;
    }

    label{
        cursor: pointer;
    }

    &--inputes:checked ~ label{
        transition: 0.2s;
        cursor: pointer;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 46.6px;
        line-height: 125%;
        color: #000;
        margin-left: -20px;        
        


        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
 

    &__inputs[type="radio"]{
        display: none;
    }

    &__inputs:checked ~ label {
        transition: 0.5s;

        &::before{
            content: "";
            position: absolute;
            width:100%;
            height: 7px;
            bottom: 0;
            left: 0;
            background-color: rgb(255, 174, 0);
        }
    }

    &__btn{
        cursor: pointer;
        width: 70%;
        height: 100%;
        padding: 10px 20px;

        &:hover::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 7px;
            bottom: 0;
            left: 0%;
            background-color:rgb(255, 206, 101);
        }
    }



    &__page{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__message{
        background: #000000;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: fixed;
        z-index: 1;
        top: 65%;
        left: 76%;
        cursor: pointer;
    }
   
    &__picture{
         margin-top: 5px;
    }


    &__search{
        border: 2px solid #000000;
        width: 310px;
        height: 39px;
        background-color: transparent;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__btns{
        background: #BEBEBE;
        width: 15px;
        height: 15px;
        border: none;
    }

    &__input{
        width: 200px;
        height: 40px;
        padding: 0 10px;
        background-color: transparent;
        outline: none;
        border: none;

        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 100;
            font-size: 15px;
            line-height: 125%;
            color: #000000;
        }
    }

    &__button{
        width: 125px;
        height: 39px;
        background-color: #000000;
        // border: 2px solid #000;
        border: solid 1px black;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        // line-height: 125%;
        color: #fff;
        &:hover{
            background-color: #232222dc;
        }
    }

    &__name{
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 700;
        font-size: 32.8495px;
        line-height: 125%;
        color: #000000;
        width: 300px;
        margin-top: 25px;
    }

    &__text{
        font-size: 20px;
        line-height: 125%;
        font-style: normal;
        font-weight: 300;
        width: 285px;
        font-family: 'Helvetica Neue', sans-serif;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        height: 100px;
    }

    &__cataloge{
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 125%;
        font-family: 'Inter';
        text-decoration: none;
        border: 1px solid #000000;
        padding: 15px 35px;
        margin: 10px 0;
        align-items: center;
        color: #000;
        transition: 0.5s;
        position: relative;
        z-index: 1;
        background-color: transparent;
        @media (max-width:500px) {
            font-size:14px;
        }
        @media (max-width:420px) {
            font-size:12px;
        }
        &:hover{
            color: white;
            // background-color: black;
            &::before{
                width: 100%;
            }
            .header__arrow{
                color: white;
            }
        }
        &::before{
            top: 0;
            left: 0;
            content: "";
            z-index: -1;
            transition: 0.5s;
            position: absolute;
            background-color: black;
            width: 0%;
            height: 100%;
        }
    }

    .header__arrow{
        // z-index: 1;
        position: sticky;
        margin-left: 10px;
        transition: 0.5s;
        @media (max-width:500px) {
            font-size: 20px;
        }
        @media (max-width:420px) {
            font-size:15px;
        }
    }
    &__titles{
        display: flex;
        align-items: center;
    }
    &__img{
        height: 430px;
    }
    &__about{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 125%;
        color: #000;
        margin: 0 25px;
        text-decoration: none;
        @media (max-width:420px) {
            font-size:12px;
        }

        &:hover{
            color: #000;
        }
    }

    &__right{
        display: flex;
    }

    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        padding-left: 50px;
        @media (max-width:767px) {
         display: none;       
        }
    }

    &__number{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 48.6px;
        line-height: 125%;
        color: #000;
        text-decoration: none;

        &:hover{
            color:#000;
        }
    }

    &__numbers{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 100;
        font-size: 34.2px;
        line-height: 125%;
        color: #000;
        opacity: 0.3;
        margin-left: 25px;
        text-decoration: none;

        &:hover{
            color: #000;
            opacity: 0.3;
        }
    }

    &__pages{
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        position: absolute;
        top: 90%;
        left: 28%;
        overflow: hidden;
        @media (max-width:1300px) {
            left: 24%;
        }
        @media (max-width:1160px) {
            left: 20%;
        }
        @media (max-width:767px) {
            left: 16%;
        }
        @media (max-width:700px) {
            left: 13%;
        }
        @media (max-width:600px) {
            left: 9%;
        }
        @media (max-width:520px) {
            left: 15%;
        }
        @media (max-width:470px) {
            left: 10vw;
        }
        @media (max-width:425px) {
            left: 7vw;
        }
        @media (max-width:370px) {
            left: 5vw;
        }
    }

    &__btn{
        background-color: transparent;
        border: none;
        margin: 0 20px;
        cursor: pointer;
    }

    &__btns{
        margin: 0 10px;
        cursor: pointer;
    }

    &__dote{
        margin-left: -12px;
    }
}

@media (max-width:1350px) {
    
    .container{
        width: 1160px;
    }
}

@media (max-width:1160px) {
    
    .container{
        width: 1000px;
    }

    .header{

        &__message{
            top: 62%;
            left: 83%;
        }        
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }

    .header{

        &__pictures{
            height: 400px;
            width: 380px;
        }

        &--labels{
            margin-left: -50px;
        }

        &__text{
            font-size: 14px;
            width: 200px;
        }       
    }
}

@media (max-width:920px) {
    
    .container{
        width: 800px;
    }
    .header{

        &__btn{
            margin: 0 10px;
        }

        &__chair{
            width: 60px;
            height: 60px;
        }
        &__chairs{
            width: 80px;
            height: 80px;
        }
        &__pictures{
            height: 350px;
            width: 340px;
        }

        &__name{
            font-size: 28px;
        }
        &__text{
            font-size: 16px;
        }
        &__page{
            justify-content: space-around;
        }       
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }

    .header{

        &__button{
            width: 80px;
        }
        &__input{
            width: 180px;
        }
        &__search{
            width: 260px;
        }

        &__inputes{
            margin-left: -50px;
        }        

        &__cataloge{
            padding: 12px 25px;
        }

        &__message{
            width: 55px;
            height: 55px;
        }
        &__picture{
            width: 40px;
            height: 40px;
        }

        &__images{
            width: 450px;
        }
    }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }

    .header{
        padding-bottom: 60px;

        &__page{
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
        }
        &__left{
            margin-top: 25px;
        }
    }
}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .header{

        &--list{
            max-height: 350px;
        }
        

        &__dote{
            margin-top: 20px;
        }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .header{

        &__message{
            left: 70%;
        }

        &__images{
            width: 380px;
        }

        &__chair{
            width: 50px;
            height: 50px;
        }
        &__chairs{
            width: 70px;
            height: 70px;
        }

        &__tip{
            width: 90px;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .header{

        &--list{
            max-height: 350px;
        }

        &__cataloge{
            padding: 12px 15px;
        }

        &__pictures{
            display: none;
        }
        &__box{
            display: none;
        }
        &__name{
            font-size: 32.8495px;
            line-height: 125%;
            width: 170px;
        }
        &__text{
            font-size: 16px;
            line-height: 125%;
        }

        &__pages{
            width: 330px;
        }

        &__images{
            width: 300px;
            display: none;
        }

        &__chair{
            width: 40px;
        }

        &__btn{
            width: 45px;
            margin-left: -20px;
        }
    }
}



.instagram-media{
    background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);
}

.instagram-media1{
    background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;
}