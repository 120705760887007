.catalogy{
    background-image: url("../../Assets/img/classicchair.jpg");
    background-repeat: no-repeat;
    background-size: 100% 120%;
    padding: 150px 0 300px 0;
    position: relative;
    margin-top: 100px;
    background-position: center;
    @media (max-width:520px) {
        padding: 60px 0 60px 0;
        background-size: 130% 100%;
        background-position: center;
    }
    @media (max-width:420px) {
        padding: 50px 0;
    }

    &__name{
        font-style: normal;
        font-weight: 400;
        font-size: 49.8751px;
        font-family: 'Inter';
        width: 450px;
        margin: 0 auto;
        line-height: 125%;
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0px 6.07317px 6.07317px rgba(0, 0, 0, 0.25);
    }

    &__title{
        width: 309.73px;
        height: 75.91px;
        text-align: center;
        margin: 0 auto;
        background-color: #000;
        margin-top: 35px;
        border: 1.51829px solid #FFFFFF;
    }

    &__link{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-left:75px;
        font-size: 22.7744px;
        line-height: 125%;
        color: #FFFFFF;
        text-decoration: none;

        &:hover{
            color: #FFFFFF;
        }
    }

    &__img{
        margin-left: 10px;
    }

    &__list{
        position: absolute;
        list-style: none;
        left: 81%;
        top: 25%;
    }

    &__item{
        margin-bottom: 15px;
    }

    &__number{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 48.6px;
        line-height: 125%;
        color: #FFFFFF;
        text-decoration: none;

        &:hover{
            color: #FFFFFF;
        }
    }

    &__numbers{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 34.2px;
        line-height: 125%;
        color: white;
        opacity: 0.7;
        text-decoration: none;
        transition: 0.3s;

        &:hover{
            color: #FFFFFF;
            font-weight: 500;
            font-size: 40px;
        }
    }

    &__lists{
        list-style: none;
        display: flex;
        align-items: center;
        position: absolute;
        top: 83%;
        left: 9.5%;
    }

    &__btn{
        width: 14px;
        height: 14px;
        background-color: #FFFFFF;
        border: none;
        outline: none;
        cursor: pointer;
    }

    &__btns{
        width: 14px;
        height: 14px;
        background: #BEBEBE;
        border: none;
        outline: none;
        cursor: pointer;
    }

    &__items{
        margin: 10px;
    }
}

@media (max-width:1350px) {
    
    .container{
        width: 1160px;
    }
}

@media (max-width:1160px) {
    
    .container{
        width: 1000px;
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }
}

@media (max-width:920px) {
    
    .container{
        width: 800px;
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }
}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .catalogy{

        &__name{
            font-size: 45px;
            width: 380px;
        }

        &__number{
            font-size: 40px;
        }
        &__numbers{
            font-size: 30px;
        }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .catalogy{
        position: relative;

        &__title{
            width: 180px;
            height: 60px;
            margin-top: 25px;
        }

        &__name{
            font-size: 35px;
            width: 300px;
        }

        &__link{
            margin-top: 12px;
            margin-left: 35px;
        }

        &__list{
            position: absolute;
            list-style: none;
            left: 77%;
            top: 35%;
        }
        
        &__number{
            font-size: 30px;
        }
        &__img{
            width: 20px;
        }
    
        &__numbers{
            font-size: 23px;
            font-weight: 400;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .catalogy{

        &__list{
            position: absolute;
            list-style: none;
            left: 72%;
            top: 35%;
        }
    }
}