.nav{
    width: 100%;
    padding: 0;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
}
.close-icon{
    position: absolute;
    color: white;
    border: solid 2px white;
    padding: 3px;
    width: 35px;
    height: 35px;
    top: 100px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000000001;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-icon{
    position: absolute;
    color: grey;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1001;
    font-size: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 38%;
    @media (max-width:1600px) {
        right: 30%;
        font-size: 30px;
    }
    @media (max-width:1000px) {
        right: 20%;
        font-size: 30px;
    }
    @media (max-width:700px) {
        right: 15%;
        font-size: 30px;
    }
    @media (max-width:600px) {
        right: 10%;
        font-size: 30px;
    }
    @media (max-width:500px) {
        right: 5%;
        font-size: 30px;
    }
    @media (max-width:420px) {
        right: 0%;
        font-size: 30px;
    }
}
.left-icon{
    position: absolute;
    color: grey;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1001;
    font-size: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 38%;
    @media (max-width:1600px) {
        left: 30%;
        font-size: 30px;
    }
    @media (max-width:1000px) {
        left: 20%;
        font-size: 30px;
    }
    @media (max-width:700px) {
        left: 15%;
        font-size: 30px;
    }
    @media (max-width:600px) {
        left: 10%;
        font-size: 30px;
    }
    @media (max-width:500px) {
        left: 5%;
        font-size: 30px;
    }
    @media (max-width:420px) {
        left: 0%;
        font-size: 30px;
    }
}

.instagram{
    position: fixed;
    z-index: 1000000001;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.76);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    .contr-story{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: black;
        z-index: -1;
    }
}

.container{
    width: 1140px;
    margin: 0 auto;
}
.navbar-splide .container{
    padding: 20px 0 40px 0;
}

.navbar__splide{
    padding-top: 120px;
    position: sticky;
    z-index: 100001;
    @media (max-width:500px) {
        padding-top: 80px;
    }
}


.navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &__modal-btn{
        background-color: transparent;
        border: none;
        outline: none;
        margin-top: 55px;
        margin-left: 120px;
    }

    &__modal-list{
        list-style: none;
    }
    &__modal-item{
        margin: 10px 0;
    }

    &__modal-link{
        text-decoration: none;
        font-size: 12px;
        color: #000;
        margin-left: -25px;
        font-weight: 500;
        font-family: 'Roboto';
        line-height: 16px;
    }

    &__modal-close{
        width: 25px;
        height: 25px;
    }

    &__btn{
        display: none;
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
    }

    &__page{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: solid 1px gold;
        width: 100%;
    }

    &__list{
        display: flex;
        align-items: center;
        list-style: none;
    }

    &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 800;
        font-size: 21.4412px;
        // line-height: 29px;
        color: #000;
        text-decoration: none;
    }

    &__item{
        padding: 0 20px;
    }

    &__link{
        text-decoration: none;        
        font-style: normal;
        font-weight: 500;
        font-size: 14.0833px;
        color: rgba(0, 0, 0, 0.5);
        transition: 0.3s;
        display: block;
        position: relative;

       &::before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            transform: scaleX(0);
            background: #3A896A;
            border-radius: 23px 10px 0px 0px;
            transition: 0.3s;
       }
       &:hover::before{
            transform: scaleX(1);
       }
       &:hover{
        color: #3A896A;
        font-weight:500;
       }
    }

    &__lists{
        display: flex;
        justify-content: center;
        width: 470px;
        margin: 0 auto;
        padding-left: 0px;
        height: 90px;
        margin-top: 30px;
    }

    &__slides{
        width: 25%;
        margin-left: 41px;
    }

    

    &__pic{
        // padding: 0 9px;
        margin: 0 10.5px;
        transition: 0.3s;
        margin-top: 15px;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        overflow: hidden;
        border: solid 2px transparent;
        background: linear-gradient(45deg, rgb(254, 218, 117),orange, crimson, purple, blue) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);      
        &:hover{
            transform: scale(1.15);
        }
    }

    &__links{
        margin-left: 13px;
    }

    &__phone{
        text-decoration: none;
        transition: 0.5s;

        &:hover{
            transform: scale(1.15);
        }
    }

    &__title{
        display: flex;
        align-items: center;
        transition: 0.5s;
    }

    &__names{
        width: 130px;
        margin-left: 10px;
        font-family: 'IntegralCF-Bold';
        font-size: 16.2778px;
        line-height: 17px;
        color: #fff;
    }

    &__items{
        background-color: #000;
        
        border-radius: 4.52162px;
        transition: 0.5s;

        &:hover{
            transform: scale(1.05);
        }
    }
}

.navbar{
    .navbar__page{
        padding: 0 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border: solid 1px blue;
        .navbar__name{
            // border: solid 1px;
            font-size: 35px;
            font-weight: 800;
            font-family: Arial, Helvetica, sans-serif;
        }
        .navbar__list{
            // border: solid 1px red;
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            li{
                list-style: none;
                a{
                    text-decoration: none;
                }
                .navbar__phone{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                }
            }
        }
    }
}

 .ReactModal__Overlay  .ReactModal__Content {
    background-color: #DBDBDB;
}

 .ReactModal__Overlay{
    background-color: red;
}

@media (max-width:1350px) {
    
    .container{
        width: 1160px;
    }
}

@media (max-width:1160px) {
    
    .container{
        width: 1000px;
    }

    .navbar{

        &__item{
            margin: 0 12px;
        }
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }

    .navbar{

        &__item{
            margin: 0 8px;
        }

        &__items{
            width: 180px;
            height: 50px;
        }
        &__names{
            font-size: 14px;
        }
    }
}

@media (max-width:920px)  {
    
    .container{
        width: 800px;
    }

 
    .navbar{

        &__link{
            font-size: 12px;
            line-height: 18px;
        }

        &__item{
            margin: 0 8px;
        }

        &__items{
            width: 160px;
            height: 50px;
        }
        &__names{
            font-size: 12px;
        }
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }

  .navbar{

    &__link{
        font-size: 10px;
        line-height: 16px;
    }

    &__item{
        margin: 0 5px;
    }
  }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }
    .navbar{

        &__link, &__items{
            display: none;
        }
        &__btn{
            display: block;
        }
        &__page{
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .navbar__lists{
        width: 380px;
    }

    .navbar__pic{
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 10px;
        margin-top: 25px;
    }
    .splide__arrow{
        width: 20px;
        height: 20px;
        font-size: 16px;
        padding: 3px;
    }
}

.navbar__button{
    border: none;
    background-color: transparent;
}

.modal__img{
    width: 25px;
    height: 25px;
}

.modal__btn{
    border: none;
    background-color: transparent;
    margin-left: 300px;
    padding-bottom: 30px;
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }
    .navbar__pic{
        width: 50px;
        height: 50px;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 3px;
        // margin-top: 25px;
    }
    .navbar__button{
        border: none;
        margin-top: 25px;
        background-color: transparent;
    }

    .navbar{

        &__lists{
            width: 330px;
            margin-left: -25px;
            margin-top: 10px;
            border: solid 1px;
            padding: 0;
        }

        &__slides{
            width: 100%;
            margin: 0;
            margin-left: 0px;
            padding: 0 10px;
        }

        &__modal-btn{
            margin-left: 80px;
        }

        &__modal-link{
            font-size: 10px;
        }
        
    }

    .modal-content{
        width: 310px;
        height: 400px;
    }
}
.modal-content{
    width: 450px;
    height: 400px;
}

.carousel{
    margin-top: 20px;
    width: 25%;
    left: 37.5%;
    padding: 0;
    // border: solid 1px;
    @media (max-width:1555px) {
        width: 30%;
        left: 35%;
    }
    @media (max-width:1300px) {
        width: 35%;
        left: 32.5%;
    }
    @media (max-width:1000px) {
        width: 40%;
        left: 30%;
    }
    @media (max-width:780px) {
        width: 50%;
        left: 25%;
    }
    @media (max-width:600px) {
        width: 70%;
        left: 15%;
        margin-top: 10px;
    }
    @media (max-width:400px) {
        width: 80%;
        left: 10%;
    }
    .slide-btn{
        border-radius: 50%;
        padding: 10px;
        width: 100%;
        height: 100%;
        margin: 0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media (max-width:450px) {
            padding: 5px;
        }
        .slide-img{           
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transition: 0.3s;       
            border-radius: 50%;
            overflow: hidden;
            border: solid 2px transparent;
            background: linear-gradient(45deg, rgb(254, 218, 117),orange, crimson, purple, blue) border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);     
        }
    }
    .slick-dots{
        display: none;
        opacity: 0;
    }
    .slick-prev{       
        left: -30px;       
        z-index: 101;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: rgb(220, 220, 220);
        top: 50%;
        @media (max-width:500px) {
            width: 25px;
            height: 25px;
            left: -25px;   
        }
    }
    .slick-prev:before{
        content: "\f104";
        font-family: FontAwesome;
        font-style: normal;   
        border-radius: 50%;         
        font-weight: 100;            
        color: black;                
        font-size: 28px;
        display: flex;                 
        align-items: center;
        justify-content: center;
        @media (max-width:500px) {
            font-size: 23px;  
        }  
    }      
    .slick-next{       
        left: 100%;       
        z-index: 101;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: rgb(220, 220, 220);
        top: 50%;
        @media (max-width:500px) {
            width: 25px;
            height: 25px;
        }              
    }
    .slick-next:before{
        content: "\f105";
        font-family: FontAwesome;
        font-style: normal;   
        border-radius: 50%;            
        font-weight: 100;            
        color: black;                
        font-size: 28px;    
        display: flex;                 
        align-items: center;
        justify-content: center;    
        @media (max-width:500px) {
            font-size: 23px;  
        }       
    } 
}
