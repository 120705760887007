.headerinput{
    /* background-color: #EEEEEE; */
    width: 100%;
    height: 948px;
    padding: 0;
    margin: 0;
}
@media (max-width:1450px){
    .headerinput{
        height: 750px;
    }
}
@media (max-width:700px){
    .headerinput{
        height: 550px;
    }
}
@media (max-width:500px){
    .headerinput{
        height: 450px;
    }
}