.Navbar .navbar-nav .nav-item .nav--link {
    color: #000;
}

.Navbar {
    padding: 20px 100px;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000001;
    background-color: black;

    @media (max-width:1555px) {
        padding: 20px 80px;
    }

    @media (max-width:1470px) {
        padding: 20px 60px;
    }

    @media (max-width:1400px) {
        padding: 20px 50px;
    }

    @media (max-width:1240px) {
        padding: 20px 20px;
    }

    @media (max-width:845px) {
        padding: 20px 10px;
    }

    @media (max-width:855px) {
        padding: 20px 0;
    }

    @media (max-width:780px) {
        padding: 20px 50px;
    }

    @media (max-width:670px) {
        padding: 20px 50px;
    }

    @media (max-width:600px) {
        padding: 20px 50px 20px 50px;
    }

    @media (max-width:600px) {
        padding: 10px 50px 10px 50px;
    }

    @media (max-width:430px) {
        padding: 10px 30px;
    }

    .w-750 {
        width: 60%;

        // border: solid 1px;
        @media (max-width:830px) {
            width: 60%;
        }

        @media (max-width:780px) {
            width: 60%;
        }

        @media (max-width:440px) {
            width: 80%;
        }

        @media (max-width:410px) {
            width: 80%;
        }

        @media (max-width:400px) {
            width: 80%;
        }

        @media (max-width:390px) {
            width: 80%;
        }
    }

    .navbar-bran {
        // margin-top: -15px;
        font-weight: 800;
        font-size: 40px;

        .text-dark {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:1400px) {
                font-size: 30px;
                margin-top: 0px;
            }

            @media (max-width:430px) {
                margin-left: -10px;
            }

            img {
                width: 80px;
                height: auto;

                @media (max-width:1100px) {
                    width: 60px;
                }

                @media (max-width:430px) {
                    width: 50px;
                }
            }
        }
    }

    .navbar-nav {
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        // border: solid 1px white;
        width: 100%;

        @media (max-width:780px) {
            z-index: 20005;
            display: block;
            position: absolute;
            top: -20px;
            right: -120vw;
            height: 450px;
            width: 100vw;
            background-color: black;
            text-align: center;
            padding: 40px 0;
            overflow: hidden;
            transition: 0.4s;
            -webkit-transition: 0.4s;
            -moz-transition: 0.4s;
            -ms-transition: 0.4s;
            -o-transition: 0.4s;
        }

        .nav-item {
            padding: 0 10px;
            font-weight: 500;
            margin: 0;

            @media (max-width:1600px) {
                padding: 0 7px;
            }

            @media (max-width:1440px) {
                padding: 0 5px;
            }

            @media (max-width:1255px) {
                padding: 0 3px;
            }

            @media (max-width:940px) {
                padding: 0;
            }

            @media (max-width:780px) {
                padding: 10px 40px;
            }

            @media (max-width:550px) {
                padding: 10px;
            }

            .tel {
                @media (min-width:780.1px) {
                    display: none;
                }
            }

            .nav--link {
                color: rgb(255, 255, 255);
            }

            .nav-link {
                font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
                    Helvetica, Arial, "Lucida Grande", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin: 0;
                // line-height: 22px;
                // margin: 10px 20px;
                color: rgba(255, 255, 255, 0.702);
                transition: 0.3s linear;

                &:hover {
                    transform: scale(0.95);
                    color: #ffffff;
                    // font-weight: 600;
                }

                &:active {
                    transform: scale(0.95);
                    color: rgb(255, 255, 255);
                    font-weight: 600;
                }

                @media (max-width:1340px) {
                    font-size: 14px;
                }

                @media (max-width:1180px) {
                    font-size: 13px;
                }

                @media (max-width:1135px) {
                    padding: 7px;
                }

                @media (max-width:990px) {
                    font-size: 12px;
                }

                @media (max-width:940px) {
                    font-size: 12px;
                }

                @media (max-width:780px) {
                    font-size: 18px;
                    color: white;
                }

                @media (max-width:550px) {
                    font-size: 18px;
                }
            }
        }
    }

    .search {
        // border: solid 1px white;
        width: 30px;
        cursor: pointer;

        @media (max-width:1400px) {
            width: 25px;
        }

        @media (max-width:1340px) {
            width: 23px;
        }
    }

    .show {
        right: -50px;

        @media (max-width:430px) {
            right: -30px;
        }
    }

    .heartly {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:780px) {
            display: none;
        }

        .fa-heart-o {
            font-size: 25px;
            font-weight: 600;
            color: rgb(255, 255, 255);
            cursor: pointer;
            position: relative;

            @media (max-width:1400px) {
                font-size: 22px;
            }

            @media (max-width:1340px) {
                font-size: 19px;
            }

            p {
                font-size: 12px;
                background-color: red;
                position: absolute;
                color: white;
                width: 12px;
                height: 11px;
                border-radius: 50%;
                padding: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -1.5px;
                right: -5px;
            }
        }
    }

    .call {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        @media (max-width:780px) {
            display: none;
        }

        i {
            font-size: 20px;

            @media (max-width:1400px) {
                font-size: 16px;
            }

            @media (max-width:1340px) {
                font-size: 14px;
            }
        }

        .span {
            font-size: 14px;
            margin: 0;
            font-family: 'Poppins';
            padding: 0;
            text-align: left;
            line-height: 20px;
            font-weight: 300;

            @media (max-width:1400px) {
                font-size: 12px;
            }

            @media (max-width:1345px) {
                font-size: 13px;
            }

            @media (max-width:940px) {
                font-size: 12px;
            }
        }

        .spans {
            font-family: 'Poppins';
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            text-align: left;
            line-height: 15px;

            // border: solid 1px white;  
            @media (max-width:1400px) {
                font-size: 16px;
            }

            @media (max-width:1340px) {
                font-size: 15px;
            }

            @media (max-width:940px) {
                font-size: 14px;
            }
        }

        .icon {
            font-size: 30px;

            @media (max-width:1400px) {
                font-size: 27px;
            }

            @media (max-width:1340px) {
                font-size: 22px;
            }

            @media (max-width:940px) {
                font-size: 22px;
            }
        }
    }

    .nav-links {
        text-decoration: none;
        background: #ffffff;
        border-radius: 4.52162px;
        padding: 8px 10px;

        @media (max-width:1420px) {
            padding: 7px;
        }

        @media (max-width:1150px) {
            padding: 7px 5px;
        }

        @media (max-width:1000px) {
            padding: 5px 3px;
        }

        @media (max-width:780px) {
            padding: 0;
            width: 100%;
        }
    }

    .dfg1 {
        @media (min-width:780.1px) {
            display: none;
        }

        .fa-heart-o {
            font-size: 28px;
            color: white;
            cursor: pointer;
            position: relative;

            @media (max-width:1400px) {
                font-size: 22px;
            }

            p {
                font-size: 10px;
                background-color: red;
                position: absolute;
                color: white;
                border-radius: 50%;
                padding: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -5px;
                right: -5px;
            }
        }
    }

    .language {
        // border: solid 1px white;     
        width: 100%;

        .change-language {
            position: relative;

            @media (max-width:780px) {
                padding-right: 20px;
            }

            #language[type="checkbox"] {
                display: none;
            }

            label {
                color: white;
                cursor: pointer;
                font-weight: 600;
                font-size: 18px;
                padding: 5px 10px;
                transition: 0.2s;
                border-radius: 5px;
                display: flex;
                align-items: center;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                -ms-border-radius: 7px;
                -o-border-radius: 7px;

                &:hover {
                    background-color: black;
                    color: white;
                    transform: scale(1.03);
                }

                &:active {
                    transform: scale(0.97);
                }

                @media (max-width:1400px) {
                    font-size: 15px;
                }

                @media (max-width:780px) {
                    font-size: 18px;
                }

                @media (max-width:500px) {
                    margin-top: 0px;
                    padding: 5px 10px;
                    font-size: 16px;
                }

                .icon {
                    font-size: 30px;

                    @media (max-width:830px) {
                        font-size: 25px;
                    }
                }
            }

            .language-menu {
                position: absolute;
                text-align: center;
                margin-top: 5px;
                left: -10px;
                padding: 0;
                background-color: rgba(0, 0, 0, 0.829);
                overflow: hidden;
                border-radius: 5px;
                z-index: 10001;

                @media (max-width:780px) {
                    left: -10px;
                }

                li {
                    list-style: none;
                    padding: 0 10px;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    -moz-transition: 0.4s;
                    -ms-transition: 0.4s;
                    -o-transition: 0.4s;

                    &:hover {
                        background-color: white;

                        .nav-link {
                            color: black;

                        }
                    }

                    @media (max-width:780px) {
                        padding: 0 5px;
                    }
                }

                .nav-link {
                    cursor: pointer;
                    color: white;
                }
            }
        }
    }

    .fa-bars {
        font-size: 23px;
        cursor: pointer;
        color: white;

        @media (min-width:780.1px) {
            display: none;
        }

        @media (max-width:500px) {
            font-size: 20px;
        }
    }

    .fa-times {
        position: absolute;
        font-size: 25px;
        top: 30px;
        right: 30px;
        cursor: pointer;
        color: white;

        @media (min-width:780.1px) {
            display: none;
        }
    }

    .responsive-lang {
        @media (min-width:780.1px) {
            display: none;
        }

        @media (max-width:780px) {
            display: flex;
            width: 100%;
        }
    }

    .res {
        @media (max-width:780px) {
            display: none;
        }
    }
}

.modal-search {
    position: absolute;
    top: 120px;
    right: 15vw;
    width: 30vw;
    height: auto;
    border-radius: 15px;
    overflow: hidden;
    backdrop-filter: blur(16px);

    @media (max-width:1000px) {
        width: 40vw;
    }

    @media (max-width:780px) {
        width: 50vw;
        right: auto;
        left: 25vw;
        top: 100px;
    }

    @media (max-width:500px) {
        width: 70vw;
        left: 15vw;
        top: 90px;
    }

    .search-icon {
        position: absolute;
        color: white;
        top: 30px;
        opacity: 0.7;
        left: 15px;

        @media (max-width:780px) {
            top: 15px;
            left: 10px;
        }
    }

    input {
        width: 100%;
        outline: none;
        border: none;
        padding: 25px;
        color: white;
        background-color: rgba(0, 0, 0, 0.672);
        font-size: 20px;
        padding-left: 50px;
        backdrop-filter: blur(16px);

        @media (max-width:780px) {
            padding: 15px;
            padding-left: 50px;
            font-size: 16px;
        }
    }

    .search-tool {
        width: 100%;
        max-height: 350px;
        overflow-y: scroll;
        background-color: rgba(0, 0, 0, 0.48);
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        backdrop-filter: blur(16px);

        @media (max-width:780px) {
            max-height: 300px;
        }

        ul {
            padding: 10px;

            li {
                list-style: none;
                width: 100%;

                .search-link {
                    color: white;
                    opacity: 0.7;
                    cursor: pointer;
                    width: 100%;
                    border-radius: 10px;
                    padding: 10px;
                    transition: 0.3s;
                    display: flex;
                    align-items: center;
                    justify-content: start;

                    @media (max-width:780px) {
                        padding: 5px;
                    }

                    &:hover {
                        padding-left: 40px;
                        background-color: rgba(0, 0, 0, 0.672);
                    }

                    .launch {
                        font-size: 18px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

.modal-about,
.modal-best,
.modal-categs,
.modal-prods {
    // margin-left: -70px;
    left: -20px;
    top: 85px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.48);
    border-radius: 15px;
    overflow: hidden;
    width: auto;
    backdrop-filter: blur(16px);
    padding: 0;
    margin: 0;
    display: block;
    max-height: 500px;
    overflow-y: scroll;

    @media (max-width:1400px) {
        max-height: 450px;
    }

    .lin {
        display: block;
        color: white;
        text-decoration: none;
        width: 100%;
        padding: 25px 80px;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.391);
            opacity: 1;
        }

        @media (max-width:1400px) {
            padding: 25px 70px;
        }
    }

    @media (max-width:780px) {
        display: none;
    }
}

.modal-best {
    right: 40%;
    left: auto;

    @media (max-width:1500px) {
        right: 30%;
    }

    @media (max-width:1400px) {
        right: 27%;
    }
}

.modal-categs {
    left: 18%;
}

.modal-prods {
    left: 10%;
}

.contr-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
}

.cookie {
    position: fixed;
    width: 100%;
    height: 350px;
    bottom: 0;
    left: 0;
    z-index: 111111111111111;
    background-color: rgba(255, 255, 255, 0.83);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 30px 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width:1500px) {
        padding: 20px 7vw;
    }

    @media (max-width:1000px) {
        height: 400px;
        padding: 20px 5vw;
    }

    @media (max-width:500px) {
        height: 440px;
        padding: 5vw;
    }

    p {
        display: block;
        width: 100%;

        @media (max-width:1500px) {
            font-size: 14px;
        }

        @media (max-width:700px) {
            font-size: 12px;
        }

        @media (max-width:500px) {
            height: 290px;
            overflow: hidden;
        }
    }

    a {
        padding: 0;
        margin: 0 10px;
        margin-top: 30px;

        @media (max-width:1500px) {
            font-size: 14px;
            margin-top: 15px;
        }

        @media (max-width:500px) {
            font-size: 14px;
            margin-top: 0px;
            margin-bottom: 10px;
        }
    }

    .btn {
        border: solid 1px black;
        border-radius: 23px;
        transition: 0.3s;
        padding: 15px 30px;
        margin: 0 10px;
        margin-top: 30px;
        background-color: black;
        color: white;

        &:hover {
            background-color: white;
            color: black;
        }

        @media (max-width:1500px) {
            font-size: 14px;
            margin-top: 15px;
        }

        @media (max-width:500px) {
            font-size: 14px;
            margin-top: 0px;
        }

        @media (max-width:380px) {
            font-size: 14px;
            padding: 15px 20px;
        }
    }

    .fa-times {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 35px;
        cursor: pointer;
    }
}

.volume {
    color: white;
    cursor: pointer;
    // font-size: 6;
}

.contr-cookie {
    background-color: transparent;
    z-index: 1111111111;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.black {
    background-color: rgb(0, 0, 0);
    transition-duration: 0.5s;
    padding: 0 100px;

    @media (max-width:1555px) {
        padding: 0px 80px;
    }

    @media (max-width:1470px) {
        padding: 0px 60px;
    }

    @media (max-width:1400px) {
        padding: 0px 50px;
    }

    @media (max-width:1240px) {
        padding: 0px 20px;
    }

    @media (max-width:845px) {
        padding: 0px 10px;
    }

    @media (max-width:855px) {
        padding: 0;
    }

    @media (max-width:780px) {
        padding: 0 50px;
    }

    @media (max-width:670px) {
        padding: 0 50px;
    }

    @media (max-width:600px) {
        padding: 0 50px 0 50px;
    }

    @media (max-width:600px) {
        padding: 0 50px 0 50px;
    }

    @media (max-width:430px) {
        padding: 0 30px;
    }
}

.white {
    background-color: rgb(39, 121, 189);
    transition-duration: 0.5s;
}

// .show{    
//     transform: translateX(-140%);
//     @media (max-width:670px) {
//         transform: translateX(-155%);
//     }
// }
a {
    cursor: pointer;
}

.navbar-logo {
    margin-left: 15px;
    height: 25px;
}