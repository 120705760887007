.Footer{
    width: 100%;
    background-color: #303030; 
    overflow: hidden;  
    .wrapper{
        padding: 50px 15vw;
        @media (max-width:1150px){
            padding: 50px 10vw;
        }
        @media (max-width:950px){
            padding: 50px 8vw;
        }
        @media (max-width:767px){
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media (max-width:500px){
            padding: 50px 30px;
        }
        @media (max-width:450px){
            padding: 50px 20px;
        }
        @media (max-width:390px){
            padding: 50px 20px;
        }
        .f1{
            align-items: center;
            justify-content: space-between;
            @media (max-width:767px){
                display: none;                           
            }
            .img{
                margin-top: 20px;
                @media (max-width:950px){
                    margin-top: 40px;                            
                }
            }
            img{
                width: 100%;
                margin-bottom: 30px;
                margin-left: 30px;
                @media (max-width:420px){
                    max-width: 80px;
                }
            }
            .red-line{
                border: solid 1px red;
            }
            label{
                display: none;
            }
            .red-label{
                position: absolute;
                display: block;
                top: 23px;
                left: 0;
                color: red;
            }
            .btns{
                margin: 0;
                padding: 0;
                margin: 10px 0;
                display: flex;
                align-items: center;
                justify-content: end;
                .btn{
                    color: white;
                    background-color: black;
                    padding: 10px 20px;
                    transition: 0.3s;
                    // margin-left: 75%;
                    &:hover{
                        background-color: white;
                        color: black;
                    }
                }
            }
            .location{
                li{
                    list-style: none;
                    h6{
                        font-weight: 400;
                        color: white;
                        @media (max-width:950px){
                            font-size: 14px;                            
                        }
                        @media (max-width:500px){
                            font-size: 14px;                            
                        }
                        @media (max-width:420px){
                            font-size: 12px;                            
                        }
                        @media (max-width:390px){
                            font-size: 12px;     
                            width: 100px;                       
                        }
                    } 
                }
            }
            .title{
                color: white;
                text-align: left;
                font-weight: 400;
            }
            .texts{
                display: flex;
                padding-top: 60px;
                @media (max-width:500px){
                    display: block;
                }
                .com{
                    @media (max-width:500px){
                        margin-left: 60vw;
                        margin-top: -27vh;                        
                    }
                    @media (max-width:420px){
                        margin-left: 55vw;
                        margin-top: -24vh;                        
                    }
                }
                .blog{
                    @media (max-width:500px){
                        margin-left: 60vw;
                        margin-top: -19vh;                        
                    }
                    @media (max-width:420px){
                        margin-left: 55vw;
                        margin-top: -19vh;                        
                    }
                }
                .prod{
                    @media (max-width:500px){                       
                        margin-top: 7vh;                        
                    }
                }
                .col-md-4{
                    position: relative;
                    .title{
                        color: white;
                        h6{
                            font-weight: 400;
                            @media (max-width:950px){
                                font-size: 14px;                            
                            }
                            @media (max-width:500px){
                                font-size: 18px;
                            }
                        }                        
                    }
                    li{
                        list-style: none;
                        text-align: left;
                        a{
                            color: rgba(192, 192, 192, 0.678);
                            text-decoration: none;
                            text-align: left;
                            @media (max-width:950px){
                                font-size: 14px;                            
                            }
                            &:hover{
                                text-decoration: underline;
                            }
                        }                        
                        margin-top: 15px;
                    }
                }
            }
            .col-md-12{
                padding: 30px 30px 0 30px;
                .row{                    
                    border-top: solid 1px rgba(187, 187, 187, 0.397);
                    justify-content: space-between;
                    padding-top: 40px;
                }
                .col-md-4{
                    h6{
                        color: white;
                        font-weight: 400;
                        @media (max-width:500px){
                            display: none;
                        }
                    }
                }
                .col-md-5{
                    display: flex;                   
                    justify-content: center;
                    h6{
                        color: white;
                        font-weight: 400;
                        @media (max-width:500px){
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .f2{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            @media (min-width:767.1px){
                display: none;                           
            }
            .img{
                margin-top: 40px;
                img{
                    width: 100%;
                    margin-bottom: 30px;
                    margin-left: 30px;
                    @media (max-width:500px){
                        width: 120%;                            
                    }                   
                }
            }
            .footer-text1{
                color: white;
                text-align: left;
                padding: 10px;
                padding-left: 0;
                @media (max-width:450px){
                    padding-right: 0;
                } 
                p{
                    text-align: left;
                    font-weight: 500;
                }
                .red-line{
                    border: solid 1px red;
                }
                label{
                    display: none;
                }
                .red-label{
                    position: absolute;
                    display: block;
                    top: 23px;
                    left: 0;
                    color: red;
                    font-size: 12px;
                    @media (max-width:500px){
                        width: 50%;
                        font-size: 11px;
                        margin: 0;
                        line-height: 10px;
                    } 
                }
                .form-control{
                    @media (max-width:500px){
                        padding: 5px;                            
                        font-size: 12px;
                    } 
                }
                .btns{
                    margin: 0;
                    padding: 0;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    .btn{
                        color: white;
                        background-color: black;
                        padding: 10px 20px;
                        transition: 0.3s;
                        // margin-left: 75%;
                        &:hover{
                            background-color: white;
                            color: black;
                        }
                        @media (max-width:500px){
                            padding: 5px 10px;                            
                            font-size: 12px;
                        }  
                    }
                }
            }
            .col-6{
                .title{
                    color: white;
                    h6{
                        font-weight: 400;
                        @media (max-width:767.1px){
                            font-size: 25px;                            
                        }
                        @media (max-width:500px){
                            font-size: 20px;
                        }
                        @media (max-width:450px){
                            font-size: 18px;
                        }
                    }                        
                }
                li{
                    list-style: none;
                    text-align: left;
                    a{
                        color: rgba(192, 192, 192, 0.678);
                        text-decoration: none;
                        @media (max-width:767.1px){
                            font-size: 18px;                            
                        }
                        &:hover{
                            text-decoration: underline;
                        }
                        @media (max-width:500px){
                            font-size: 16px;
                        }
                        @media (max-width:450px){
                            font-size: 14px;
                        }
                    }
                    @media (max-width:767.1px){
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .footer-img{
        img{
            width: 100%;
        }
    }
}