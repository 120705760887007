.slider {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 600px;
    margin-top: -7px;
    position: relative;

    &__titles{
        width: 160px;
        height: 25px;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        font-size: 16px;
        color: #fff;
    }

    &__leftBox {
        width: 51%;
        background-color: #FEC640;
        display: flex;
        // margin: 0 10px;
        align-items: center;
        justify-content: center;
        height: 500px;
    }

    &__rightBox {
        width: 49%;
        overflow: hidden;
        position: relative;
    }

    &__rightBtn{
        position: absolute;
        bottom: 5%;
        z-index: 100;
        right: 6%;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        @media (max-width:520px) {
            opacity: 0;
        }
    }

    &__leftBtn{
        position: absolute;
        bottom: 5%;
        z-index: 100;
        right: 20%;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        @media (max-width:520px) {
            // opacity: 0;
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        display: flex;
        transition: 0.4s ease;
        @media (max-width:520px) {
            display: none;
        }
    }
    .carousel{     
        @media (min-width:520.1px) {
           display: none;
        }  
        left: 0;
        width: 100%;
        .slider1{
            position: relative;
            height: 420px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            width: 100%;
            border: solid 1px white;
            margin-left: -25.5%;
            img{
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 93%;
                z-index: -1;
            }
            .text{
                // border: solid 1px red;
                position: absolute;
                bottom: 7%;
                left: 0;
                padding: 20px;
            }
            h3{
                color: white;
                z-index: 2;
            }
            p{
                color: white;
                z-index: 2;
                height: 85px;
                overflow: hidden;
            }
        }
        .slick-prev{       
            left: 8%;       
            z-index: 101;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            background: goldenrod;
            top: 96%;
        }
        .slick-prev:before{
            content: "\f104";
            font-family: FontAwesome;
            font-style: normal;   
            border-radius: 50%;         
            font-weight: 100;            
            color: black;                
            font-size: 28px;
            display: flex;                 
            align-items: center;
            justify-content: center;
        }      
        .slick-next{       
            left: 82%;       
            top: 96%;
            z-index: 101;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            background: goldenrod;              
        }
        .slick-next:before{
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;   
            border-radius: 50%;            
            font-weight: 100;            
            color: black;                
            font-size: 28px;    
            display: flex;                 
            align-items: center;
            justify-content: center;           
        } 
    }

    &__item {
        position: relative;
        &:hover{
            opacity: 0.9;
        }
    }

    &__img {
        width: 330px;
        height: 500px;
        margin: 0 0.3px;
        object-fit: cover;
        border: 1px solid #fff;
    }

    &__desc {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 0 15px 15px;
    }

    &__title {
        color: #fff;
        font-family: 'Anton', sans-serif;
        font-size: 32px;
        letter-spacing: 2px;
    }

    &__text {
        margin: 0;
        font-size: 1rem;
        color: #fff;
        width: 80%;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
    }

    a {
        font-family: 'Anton', sans-serif;
        text-decoration: none;
        font-size: 14px;
        color: #fff;
        letter-spacing: 1px;
        cursor: pointer;
    }

}

.infos {
    width: 60%;
    @media (max-width:1400px) {
        width: 70%;
    }
    &__title {
        font-size: 35px;
        letter-spacing: 1.5px;
        line-height: 45px;
        margin-bottom: 20px;
        font-weight: 800;
    }

    &__text {
        font-size: 1.2rem;
        font-weight: 500px;
        margin-bottom: 30px;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
    }

    a {
        font-family: 'Anton', sans-serif;
        text-decoration: none;
        font-size: 14px;
        color: #191919;
        letter-spacing: 1px;
        cursor: pointer;
        font-weight: 600;
        transition: 0.3s;
        &:hover{
            color: rgb(84, 83, 83);
            margin-left: 10px;
        }
    }
    a::first-letter{
        text-transform: uppercase;
    }
}

@media (max-width:820px) {
    
}


@media screen and (max-width: 778px) {
    .slider {
        height: 100%;
        padding-top: 0;
        flex-direction: column;

        &__leftBox {
            height: 50%;
            width: 100%;
        }

        &__rightBox {
            height: 30%;
            width: 100%;
        }

        &__item {
            height: 500px;
        }
    
        &__img {
            height: 500px;
            width: 225px;
        }
        &__rightBtn{            
            bottom: 0;            
        }
    
        &__leftBtn{
            bottom: 0%;  
            left: 6%;                    
            right: auto;                    
        }
    }
    
    .infos {
        padding: 45px 0; 
        width: 70%;
    }

}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .slider{

        &__img{
            width: 280px;
        }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }
    .slider{

        &__img{
            width: 220px;
        }
    }
}


@media (max-width:420px) {
    
    .container{
        width: 320px;
    }

    .slider{

        &__rightBtn{
            position: absolute;
            top: 40%;
            z-index: 100;
            left: 80%;
            background-color: transparent;
            border: none;
            outline: none;
        }

        &__leftBtn{
            top: 40%;
        }

        &__rightBox{
            margin-top: -25px;
        }

        &__img{
            width: 320px;
        }
        &__rightBtn{            
            bottom: -53%;            
        }
    
        &__leftBtn{
            bottom: -53%;  
            left: 10%;                    
            right: auto;
        }
    }
}
.blog-slider{
    margin: 0;
    height: auto;
    margin-top: 20px;
    margin-bottom: -20px;
    @media (max-width:520px){
        padding: 0;
        height: auto;
        margin-bottom: -10px;
    }    
    .slider__leftBox{
        margin-top: 50px;
        @media (max-width:520px){
            margin-top: 0px;
        }
    }
    .slider1{
        img{
            height: 100% !important;
        }
    }
    .slider__rightBox{
        padding-top: 50px;
        @media (max-width:520px){
            padding-top: 0px;
            padding: 0;
            height: auto;
        }
    }    
    .slider__rightBtn, .slider__leftBtn{
        top: -88%;
    }
    .slick-prev, .slick-next{       
        margin-top: -97%;
    }               
}