.gradient{
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .d-none{
        display: none;
    }
    .icon{
        color: white;
        border: solid 1px white;
        border-radius: 50%;
        padding: 5px;
        font-size: 45px;
        position: absolute;
        top: 50px;
        z-index: 10;
        cursor: pointer;
    }
    .contr-tour{
        z-index: 100;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.364);
        width: 100%;
        height: 99%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }

    &__name{
        font-family: 'Monument Extended';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        color: #FFFFFF;
        text-align: center;
        // position: absolute;
        // top: 35%;
        // left: 36%;
        z-index: 1001;
        @media (max-width:500px) {
            font-size: 23px;
        }
    }

    &__number{
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 110.089px;
        line-height: 125%;
        color: #FFFFFF;
        // position: absolute;
        // top: 45%;
        // left: 44%;
        z-index: 1001;
        @media (max-width:500px) {
            font-size: 70px;
        }
    }
} 

.virtual-iframe{
    width: 100%;
    height: 600px;
    cursor: pointer;
}

@media (max-width:420px) {
    
    .container{
        width: 340px;
    }

    .gradient{

        &__name{
            font-size: 20px;
            line-height: 24px;
            // left: 18%;
        }

        &__number{
            font-size: 70px;
            // left: 38%;
            line-height: 35px;
        }
    }
}