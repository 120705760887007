@font-face {
    font-family: font1;
    src: url("../fonts/font2.ttf");
}

.Home {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 948px;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    @media (max-width:1450px) {
        height: 750px;
    }

    @media (max-width:700px) {
        height: 550px;
    }

    @media (max-width:500px) {
        height: 450px;
    }

    .carousel {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100%;
        overflow: hidden;
        // border: solid 1px red;
        left: 0;
        top: 0;

        .page-slide {
            padding: 0;
            margin: 0;
            width: 100vw;
            height: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 948px;
                object-fit: cover;

                @media (max-width:1450px) {
                    height: 750px;
                }

                @media (max-width:700px) {
                    height: 550px;
                    width: 120%;
                    margin-left: -10%;
                }

                @media (max-width:500px) {
                    height: 460px;
                    width: 150%;
                    margin-left: -15%;
                }

                @media (max-width:390px) {
                    height: 450px;
                    width: 160%;
                    margin-left: -20%;
                }
            }

            .text {
                // border: solid 1px blue;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 5;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 0 30vw;
                padding-top: 20px;

                @media (max-width:1450px) {
                    padding: 0 25vw;
                }

                @media (max-width:700px) {
                    padding: 0 15vw;
                    padding-top: 30px;
                }

                @media (max-width:500px) {
                    padding: 0 12vw;
                    padding-top: 30px;
                }

                h1 {
                    text-align: left;
                    width: 100%;
                    font-size: 60px;
                    font-weight: 300;
                    font-family: font1;

                    @media (max-width:1450px) {
                        font-size: 47px;
                    }

                    @media (max-width:700px) {
                        font-size: 40px;
                    }

                    @media (max-width:450px) {
                        font-size: 25px;
                    }
                }

                p {
                    margin-top: 20px;
                    text-align: left;
                    width: 100%;
                    font-size: 18px;

                    @media (max-width:1450px) {
                        font-size: 16px;
                        margin-top: 15px;
                    }

                    @media (max-width:700px) {
                        font-size: 14px;
                    }

                    @media (max-width:450px) {
                        margin-top: 5px;
                        font-size: 13px;
                    }
                }

                .link {
                    margin-top: 30px;
                    width: 100%;
                    padding: 0;

                    @media (max-width:450px) {
                        margin-top: 15px;
                    }

                    .explore {
                        // width: 100%;
                        text-decoration: none;
                        text-transform: uppercase;
                        background-color: black;
                        opacity: 0.5;
                        color: white;
                        padding: 13px 45px;
                        transition: 0.3s;

                        &:hover {
                            opacity: 1;
                        }

                        @media (max-width:1200px) {
                            padding: 11px 35px;
                        }

                        @media (max-width:700px) {
                            padding: 9px 30px;
                            font-size: 14px;
                        }

                        @media (max-width:450px) {
                            padding: 9px 15px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .slick-dots {
            opacity: 1;
            z-index: 3;
            bottom: 10%;

            @media (max-width:450px) {
                bottom: 6%;
            }

            li {
                transition: 0.5s;

                button {
                    transition: 0.5s;

                    &::before {
                        transition: 0.5s;
                        width: 13px;
                        height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: black;
                        border-radius: 50%;

                        @media (max-width:450px) {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .slick-active {
                transition: 0.5s;

                button {
                    transition: 0.5s;

                    &::before {
                        transition: 0.5s;
                        width: 30px;
                        height: 13px;
                        margin-left: -8px;
                        // transform: scale(2.5, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(0, 0, 0);
                        border-radius: 6px;

                        @media (max-width:450px) {
                            width: 25px;
                            height: 10px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        .slick-prev {
            left: 12%;
            z-index: 101;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background: white;
            top: 55%;
            box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.269);
            transition: 0.2s;

            &:active {
                width: 42px;
                height: 42px;
                box-shadow: 0 0 0px 7px rgba(255, 255, 255, 0.269);
            }

            @media (max-width:1200px) {
                left: 10%;
                width: 35px;
                height: 35px;
                box-shadow: 0 0 0px 7.5px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 32px;
                    height: 32px;
                    box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.269);
                }
            }

            @media (max-width:700px) {
                left: 5%;
                width: 25px;
                height: 25px;
                box-shadow: 0 0 0px 6px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 22px;
                    height: 22px;
                    box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.269);
                }
            }

            @media (max-width:450px) {
                left: 6px;
                width: 23px;
                height: 23px;
                box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 21px;
                    height: 21px;
                    box-shadow: 0 0 0px 3px rgba(255, 255, 255, 0.269);
                }
            }
        }

        .slick-prev:before {
            content: "\f104";
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: FontAwesome;
            font-style: normal;
            border-radius: 50%;
            font-weight: 100;
            color: black;
            font-size: 28px;

            @media (max-width:700px) {
                font-size: 20px;
            }
        }

        .slick-next {
            right: 12%;
            left: auto;
            z-index: 101;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background: white;
            top: 55%;
            box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.269);
            transition: 0.2s;

            &:active {
                width: 42px;
                height: 42px;
                box-shadow: 0 0 0px 7px rgba(255, 255, 255, 0.269);
            }

            @media (max-width:1200px) {
                right: 10%;
                width: 35px;
                height: 35px;
                box-shadow: 0 0 0px 7.5px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 32px;
                    height: 32px;
                    box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.269);
                }
            }

            @media (max-width:700px) {
                right: 5%;
                width: 25px;
                height: 25px;
                box-shadow: 0 0 0px 6px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 22px;
                    height: 22px;
                    box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.269);
                }
            }

            @media (max-width:450px) {
                right: 6px;
                width: 23px;
                height: 23px;
                box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.269);

                &:active {
                    width: 21px;
                    height: 21px;
                    box-shadow: 0 0 0px 3px rgba(255, 255, 255, 0.269);
                }
            }
        }

        .slick-next:before {
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;
            border-radius: 50%;
            font-weight: 100;
            color: black;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:700px) {
                font-size: 20px;
            }
        }
    }
}