.flags{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.wrapper{
    /* padding: 20px 0; */
    text-align: center;
}
.title{
    font-weight: 800;
    text-align: center;
}
.img{
    width: 100%;
    height:600px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}
.flag-img{
    width: 33%;
    height: 100%;
}

@media (max-width:1200px) {
    .img{
        height: 500px;
    }
}
@media (max-width:1000px) {
    .img{
        height: 400px;
    }
}
@media (max-width:700px) {
    .img{
        height: 300px;
    }
}
@media (max-width:500px) {
    .img{
        height: 200px;
    }
}
@media (max-width:400px) {
    .img{
        height: 150px;
    }
}