.sofa {
    padding: 50px 0;
    position: relative;

    @media (max-width:620px) {
        padding: 0;
    }

    &--container {
        width: 1340px;
    }

    &--item {
        // width: 600px;
        height: 240px;
        margin: 10px;
        display: flex;
    }

    &--link:nth-child(1) {
        background: #752f29;
        width: 620px;
        height: 220px;
        margin: 10px;
        transition: 0.3s;

        &:hover {
            background: #4F1F1B;
            transform: scale(1.05);
        }
    }

    &--link:nth-child(2) {
        background: #bbbcc2;
        width: 615px;
        height: 450px;
        margin: 10px;
        margin-left: -30px;
        transition: 0.3s;

        &:hover {
            background: #9b9ca1;
            transform: scale(1.05);
        }
    }

    &--link:nth-child(3) {
        background: #434343;
        width: 620px;
        height: 220px;
        margin: 10px;
        margin-top: -230px;
        transition: 0.3s;

        &:hover {
            background: #242424;
            transform: scale(1.05);
        }
    }

    &--link:nth-child(4) {
        background: #fed678;
        height: 240px;
        margin: 10px;
        width: 1000px;
        margin: 0 auto;
        margin-top: 30px;
        transition: 0.3s;

        &:hover {
            background: #FFC740;
            transform: scale(1.05);
        }
    }

    &--link {
        text-decoration: none;
    }

    &--title {
        margin-top: 30px;
        margin: 0 10px;
        margin-left: 60px;

        @media (max-width:620px) {
            margin: 0;
        }
    }

    &--list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }


    &--text:nth-child(2),
    &--text:nth-child(3),
    &--text:nth-child(1) {
        font-size: 14px;
        color: #fff;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        height: 80px;
        width: 250px;
    }

    &--name:nth-child(1),
    &--name:nth-child(2),
    &--name:nth-child(3) {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #fff;
        // font-family: "Inter", sans-serif;
    }

    &--name:nth-child(4) {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #000;
        // font-family: "Inter", sans-serif;
    }

}

.title {
    color: black;
    text-align: center;
    margin: 0;
    padding: 0;
    // margin-bottom: 100px;
}

.sofa--link {
    position: relative;
    overflow: hidden;

    .hover-effect {
        position: absolute;
        width: 140%;
        // height: 210%;
        background-color: rgba(255, 255, 255, 0.274);
        // background: linear-gradient(39deg, rgba(255, 255, 255, 0.703) 0%, rgba(246, 245, 205, 0.669) 47%, rgba(255, 255, 255, 0.589) 100%);
        top: -60%;
        left: -25%;
        transition: 0.6s;
        transform: rotate(15deg);
    }

    &:hover {
        .hover-effect {
            height: 0%;
        }
    }
}

.sofa--link:nth-child(4) .sofa--name {
    color: #000;
}

.sofa--link:nth-child(4) .sofa--text {
    color: #656565;
}

.sofa--link:nth-child(4) .sofa--img {
    width: 550px;
    // filter: drop-shadow(0 0 7px white);
    object-fit: contain;
}

.sofa--link:nth-child(3) .sofa--img {
    width: 250px;
    height: 220px;
    object-fit: contain;
    // filter: drop-shadow(0 0 3px white);
}

.sofa--link:nth-child(1) .sofa--img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    // filter: drop-shadow(0 0 3px white);
}

.sofa--link:nth-child(1) .sofa--text {
    // margin-top: 40px;
    margin-left: 40px;
}

.sofa--link:nth-child(1) .sofa--name {
    margin-left: 40px;
    margin-top: 20px;
}

.sofa--link:nth-child(2) .sofa--img {
    height: 380px;
    width: 320px;
    // filter: drop-shadow(0 0 7px white);
    object-fit: contain;
}

.sofa--link:nth-child(2) .sofa--name {
    margin-left: -70px;
    margin-top: 40px;
}

.sofa--link:nth-child(2) .sofa--text {
    margin-left: -70px;
}



@media (max-width:1399px) {
    .container {
        width: 1200px;
    }

    .sofa--link:nth-child(1) {
        width: 550px;
    }

    .sofa--link:nth-child(3) {
        width: 550px;
    }

    .sofa--link:nth-child(1) .sofa--text {
        margin-left: -20px;
    }

    .sofa--link:nth-child(1) .sofa--name {
        font-size: 23px;
    }

    .sofa--link:nth-child(1) .sofa--name {
        margin-left: -20px;
        margin-top: 20px;
        font-size: 23px;
    }

    .sofa--link:nth-child(3) .sofa--text {
        margin-left: -40px;
    }

    .sofa--link:nth-child(3) .sofa--name {
        margin-left: -40px;
        font-size: 23px;
    }

    .sofa--link:nth-child(2) {
        width: 520px;
        margin-left: -20px;
    }

    .sofa--link:nth-child(2) .sofa--text {
        font-size: 14px;
        width: 150px;
    }

    .sofa--link:nth-child(2) .sofa--name {
        margin-left: -70px;
        font-size: 23px;
    }
}

@media (max-width:1220px) {

    .container {
        width: 1100px;
    }

    .sofa--link:nth-child(4) {
        width: 600px;
    }

    .sofa--link:nth-child(4) .sofa--img {
        width: 300px;
        height: 220px;
    }

    .sofa--link:nth-child(4) .sofa--name {
        font-size: 23px;
        margin-left: -50px;
    }

    .sofa--link:nth-child(4) .sofa--text {
        margin-left: -50px;
    }

    .sofa--link:nth-child(1) {
        width: 600px;
        margin: 0 auto;
    }

    .sofa--link:nth-child(3) {
        width: 600px;
        margin-top: 0;
        margin: 0 auto;
    }

    .sofa--link:nth-child(1) .sofa--text {
        margin-left: -20px;
    }

    .sofa--link:nth-child(1) .sofa--name {
        font-size: 23px;
    }

    .sofa--link:nth-child(1) .sofa--name {
        margin-left: -20px;
        margin-top: 20px;
        font-size: 23px;
    }

    .sofa--link:nth-child(3) .sofa--text {
        margin-left: -40px;
    }

    .sofa--link:nth-child(3) .sofa--name {
        margin-left: -40px;
        font-size: 23px;
    }

    .sofa--link:nth-child(2) {
        width: 600px;
        margin: 20px auto;
        // margin-left: -20px;
    }

    .sofa--link:nth-child(2) .sofa--text {
        font-size: 14px;
        width: 260px;
    }

    .sofa--link:nth-child(2) .sofa--name {
        margin-left: -70px;
        font-size: 23px;
    }
}

@media (max-width:1020px) {

    .container {
        width: 900px;
    }
}

@media (max-width:920px) {

    .container {
        width: 800px;
    }
}

@media (max-width:820px) {

    .container {
        width: 700px;
    }

    .sofa--link:nth-child(1) {
        margin-left: -35px;
    }

    .sofa--link:nth-child(2) {
        margin-left: -35px;
    }

    .sofa--link:nth-child(3) {
        margin-left: -35px;
    }

    .sofa--link:nth-child(4) {
        margin-left: -35px;
    }
}

@media (max-width:720px) {

    .container {
        width: 600px;
    }


    .sofa--link:nth-child(1) {
        margin-left: 0;
    }

    .sofa--link:nth-child(2) {
        margin-left: 0;
        height: 380px;
    }

    .sofa--link:nth-child(2) .sofa--text {
        width: 180px;
    }

    .sofa--link:nth-child(2) .sofa--img {
        width: 260px;
        height: 350px;
    }

    .sofa--link:nth-child(3) {
        margin-left: 0;
    }

    .sofa--link:nth-child(4) {
        margin-left: 0;
    }

    .sofa--link:nth-child(4) .sofa--img {
        width: 280px;
    }
}

@media (max-width:620px) {

    .container {
        width: 500px;
    }

    .sofa--link:nth-child(1) {
        width: 480px;
    }

    .sofa--link:nth-child(1) .sofa--img {
        width: 180px;
        height: 180px;
    }

    .sofa--link:nth-child(1) .sofa--text {
        width: 200px;
    }

    .sofa--link:nth-child(2) {
        width: 480px;
        height: 330px;
    }

    .sofa--link:nth-child(2) .sofa--img {
        height: 300px;
        width: 220px;
    }

    .sofa--link:nth-child(3) .sofa--img {
        width: 210px;
        height: 180px;
    }

    .sofa--link:nth-child(3) .sofa--text {
        width: 180px;
        font-size: 12px;
    }

    .sofa--link:nth-child(3) {
        width: 480px;
    }

    .sofa--link:nth-child(4) {
        width: 480px;
    }

    .sofa--link:nth-child(4) .sofa--img {
        width: 220px;
        height: 200px;
    }

    .sofa--link:nth-child(4) .sofa--text {
        width: 180px;
    }

}

@media (max-width:520px) {

    .container {
        width: 400px;
    }

    .sofa--link:nth-child(1) {
        width: 390px;
        height: 180px;
    }

    .sofa--link:nth-child(1) .sofa--img {
        width: 150px;
        height: 150px;
    }

    .sofa--link:nth-child(3) {
        width: 390px;
        height: 190px;
    }

    .sofa--link:nth-child(3) .sofa--img {
        width: 180px;
        height: 150px;
        margin-top: 10px;
    }

    .sofa--link:nth-child(3) .sofa--name {
        font-size: 20px;
        line-height: 22px;
        width: 120px;
    }

    .sofa--link:nth-child(4) {
        width: 390px;
        height: 200px;
    }

    .sofa--link:nth-child(4) .sofa--img {
        width: 200px;
        height: 180px;
    }

    .sofa--link:nth-child(4) .sofa--name {
        font-size: 20px;
        line-height: 22px;
        width: 120px;
    }

    .sofa--link:nth-child(4) .sofa--text {
        width: 160px;
        font-size: 10px;
    }

    .sofa--link:nth-child(2) {
        width: 380px;
    }

    .sofa--link:nth-child(2) .sofa--name {
        font-size: 20px;
        line-height: 22px;
    }

    .sofa--link:nth-child(2) .sofa--text {
        margin-left: 0px;
        width: 140px;
        font-size: 10px;
    }

    .sofa--link:nth-child(2) .sofa--name {
        margin-left: 0px;
    }

    .sofa--name,
    .sofa--text {
        margin-left: 0;
    }

    .sofa--link:nth-child(1) .sofa--text {
        font-size: 10px;
        // width: 160px;
        height: 60px;
    }


    .sofa--link:nth-child(3) .sofa--text {
        font-size: 10px;
        width: 160px;
    }
}

@media (max-width:620px) {

    .sofa--link:nth-child(1) .sofa--name,
    .sofa--link:nth-child(1) .sofa--text {
        margin-left: 0;
    }

    .sofa--link:nth-child(2) .sofa--name,
    .sofa--link:nth-child(2) .sofa--text {
        margin-left: 0;
    }

    .sofa--link:nth-child(3) .sofa--name,
    .sofa--link:nth-child(3) .sofa--text {
        margin-left: 0;
    }

    .sofa--link:nth-child(4) .sofa--name,
    .sofa--link:nth-child(4) .sofa--text {
        margin-left: 0;
    }
}

@media (max-width:420px) {

    .container {
        width: 350px;
        padding-bottom: 50px;
    }

    .sofa--link:nth-child(1) {
        width: 340px;
        margin-left: -10px;
    }

    .sofa--link:nth-child(1) .sofa--text {
        font-size: 8px;
        width: 100px;
    }

    .sofa--link:nth-child(1) .sofa--name {
        line-height: 22px;
        font-size: 18px;
    }

    .sofa--link:nth-child(2) {
        width: 320px;
        height: 240px;
        margin-left: -10px;
    }

    .sofa--link:nth-child(2) .sofa--text {
        width: 110px;
        height: 92px;
        // border: solid 1px red;
    }

    .sofa--link:nth-child(2) .sofa--img {
        width: 180px;
        height: 200px;
    }

    .sofa--link:nth-child(2) .sofa--name {
        line-height: 22px;
        font-size: 18px;
    }

    .sofa--link:nth-child(3) {
        width: 320px;
        margin-left: -10px;
    }

    .sofa--link:nth-child(3) .sofa--img {
        width: 150px;
        height: 150px;
    }

    .sofa--link:nth-child(3) .sofa--name {
        font-size: 18px;
        line-height: 22px;
    }

    .sofa--link:nth-child(3) .sofa--text {
        font-size: 8px;
        width: 130px;
        height: 60px;
    }

    .sofa--link:nth-child(4) {
        width: 320px;
        height: 160px;
        margin-left: -10px;
    }

    .sofa--link:nth-child(4) .sofa--img {
        width: 180px;
        height: 130px;
    }

    .sofa--link:nth-child(4) .sofa--text {
        font-size: 8px;
        width: 100px;
        height: 60px;
    }

    .sofa--name,
    .sofa--text {
        margin-left: 0;
    }
}

.sofa {
    .wrapper {
        gap: 3vw;
        display: grid;
        padding: 5vw 10vw;
        grid-template-columns: repeat(2, 2fr);

        @media (max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .info {
            height: 18vw;
            padding: 1vw;
            display: flex;
            align-items: center;
            text-decoration: none;
            background-color: #752f29;
            justify-content: space-between;

            &:nth-child(2) {
                background-color: #bbbcc2;
            }

            &:nth-child(3) {
                background-color: #434343;
            }

            &:nth-child(4) {
                background-color: #f5c149;
            }

            @media (max-width:767px) {
                padding: 2vw;
                height: 30vw;
            }

            .image {
                display: flex;
                max-width: 35%;
                min-width: 35%;
                max-height: 100%;
                min-height: 100%;
                object-fit: contain;
                justify-content: flex-start;
            }

            .texts {
                width: 55%;

                .s_name {
                    color: white;
                    font-size: 1.8vw;
                    text-align: left;

                    @media (max-width:767px) {
                        font-size: 3.7vw;
                    }
                }

                .s_text {
                    color: white;
                    font-size: 0.9vw;
                    text-align: left;

                    @media (max-width:767px) {
                        font-size: 2vw;
                    }
                }
            }
        }
    }
}