.footer-text {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 13.5px;
    line-height: 140%;
    letter-spacing: 0.8px;
    color: #FFFFFF;
    width: 380px;
    height: 75px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
}

.footer-item{
    margin: 7px 0;
}

.footer-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-link {
    font-style: normal;
    font-weight: 400;
    font-size: 13.4281px;
    line-height: 19px;
    color: #BEBEBE;
    padding: 5px 0;
    transition: 0.3s;
    display: block;
    position: relative;
    padding-bottom: 5px;
    text-decoration: none;
}

.footer-link:hover{
    color: #fff;
}

.footer-link::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 2px;
    transform: scaleX(0);
    background: #fff;
    border-radius: 23px 10px 0px 0px;
    transition: 0.3s;
}

.footer-link:hover::before{
    transform: scaleX(1);
}



.footer-title {
    color: white;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
}

.footer-list {
    margin: 0px 60px;
    list-style: none;
}

.footer-list:nth-child(3) {
    margin-right: 0;
}

.footer-top {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;
    /* flex-direction: column; */
}

.footer-right {
    margin-left: auto;
}



.footer-header {
    padding: 80px 0;
    background-color: #303030;
}

.footer-bottom {
    padding: 30px 0;
    background-color: black;
}

.footer-bottom .container,
.bottom-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}
.bottom-list p{
    padding: 0;
    margin: 0;
}

.bottom-list{
    display: flex;
    align-items: center;
}

.bottom-text {
    font-style: normal;
    font-weight: 400;
    font-size: 11.8686px;
    line-height: 15px;
    color: #FFFFFF;

}

.bottom-subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 11.8686px;
    line-height: 15px;
    color: #FFFFFF;
    margin-top: 18px;
}

.bottom-item {
    margin: 0px 15px;
}



@media (max-width:1450px) {
   .container{
       width: 1280px;
   }
}


@media (max-width:1290px) {
   .container{
       width: 1170px;
   }
}

@media (max-width:1180px) {
   .container{
       width: 1000px;
   }
}
@media (max-width:1020px) {
   .container{
       width: 900px;
   }
}

@media (max-width:920px) {
   .container{
       width: 800px;
   }
   .footer-title{
       font-size: 14px;
   }
   .footer-link{
       font-size: 12.4281px;
   }
}
@media (max-width:820px) {
   .container{
       width: 700px;
   }
   .footer-text {
       font-family: 'Helvetica Neue';
       font-style: normal;
       font-weight: 300;
       font-size: 11.5px;
       line-height: 132%;
       letter-spacing: 0.8px;
       color: #FFFFFF;
       height: 97px;
       text-overflow: ellipsis;
       white-space: wrap;
       overflow: hidden;
       margin-bottom: 0px;
   }
   .footer-right{
       display: flex;
       align-items: flex-start;
   }
   .footer-title {
       width: 78px;
       font-size: 14px;
   }
   .footer-list {
       margin: 0px 40px;
   }
} 
@media (max-width:720px) {
   .container{
       width: 600px;
   }
   .footer-text{
       height: 89px;
       font-size: 9.5px;
   }
   .bottom-text{
       display: none;
   }
} 
@media (max-width:620px) {
   .container{
       width: 500px;
   }

   .footer-list{
    margin: 20px 20px;
   }
} 
@media (max-width:520px) {
   .container{
       width: 400px;
   }
   .footer-right{
       display: flex;
       justify-content: space-between;
       align-items: flex-start;
       /* flex-direction: column; */
       width: 100%;
       flex-wrap: wrap;
   }
   .footer-list{
       width: 30%;
       margin: 20px 30px;
   }
   .footer-header {
       padding: 30px 0;
   }
} 
@media (max-width:420px) {
   .container{
       width: 330px;
   }

   .footer-list{
    width: 45%;
    margin: 20px 5px;
    }

    .footer-link{
        font-size: 11px;
    }
    .bottom-list p{
        padding: 0;
        margin: 0;
        font-size: 10px;
    }

}