.catalog{
    background-image: url("../../Assets/img/chair.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 150px 0 300px 0;
    margin-top: 100px;
    @media (max-width:520px) {
        padding: 60px 0 60px 0;
        background-size: 130% 100%;
        background-position: center;
    }
    @media (max-width:420px) {
        padding: 50px 0;
    }

    &__list{
        position: absolute;
        list-style: none;
        left: 80%;
        top: 27%;
    }

    &__lists{
        display: flex;
        align-items: center;
        position: absolute;
        left: 8%;
        top: 88%;
        list-style: none;
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }
    .catalog{

        &__list{
            left: 75%;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .catalog{

        &__list{
            top: 35%;
            left: 75%;
        }
    }
}