*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.row{
    --bs-gutter-x: 0;
}
html{
    scroll-behavior: smooth;
}
p::first-letter{
    text-transform: uppercase;
}
h1::first-letter{
    text-transform: uppercase;
}

h2::first-letter{
    text-transform: uppercase;
}
h3::first-letter{
    text-transform: uppercase;
}
h4::first-letter{
    text-transform: uppercase;
}
h5::first-letter{
    text-transform: uppercase;
}
h6::first-letter{
    text-transform: uppercase;
}
a::first-letter{
    text-transform: uppercase;
}
span::first-letter{
    text-transform: uppercase;
}
div::first-letter{
    text-transform: uppercase;
}
.buy-modal{
    // display: none;
    position: fixed;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    transition: 0.4s;
    z-index: 500005;
    background-color: white;    
    align-items: center;
    justify-content: center;
    padding: 40px 100px;   
    overflow-y: scroll; 
    @media (max-width:1400px){
        padding: 40px 50px; 
    }
    @media (max-width:1050px){
        padding: 40px 10px; 
    } 
    @media (max-width:950px){
        width: 80%;
        left: 10%;
    } 
    @media (max-width:767px){
        width: 70%;
        left: 15%;
        padding: 40px 50px;
    } 
    @media (max-width:600px){
        width: 70%;
        left: 15%;
        padding: 40px 10px;
    } 
    @media (max-width:450px){
        width: 80%;
        left: 10%;
        padding: 40px 10px;
    }  
    @media (max-width:380px){
        width: 90%;
        left: 5%;
        padding: 40px 10px;
    } 
    h1{
        color: black;
        text-align: center;
        @media (max-width:500px){
            font-size: 25px;
        } 
    }
    .row{
        .col-md-6{
            margin-top: 70px;
            @media (max-width:1300px){
                padding: 0 20px;
            }
            @media (max-width:767px){
                margin-top: 40px;
            }
            .price{
                text-align: left;
                font-size: 30px;
                @media (max-width:1300px){
                    font-size: 28px;
                }
                @media (max-width:500px){
                    font-size: 22px;
                }   
            }
            .col-md-12{            
                position: relative;
                cursor: pointer;
                background-color: white;
                transition: 0.3s;        
                // border: solid 1px; 
                padding-right: 150px;      
                @media (max-width:1700px){
                    padding-right: 100px;
                }
                @media (max-width:1550px){
                    padding-right: 50px;
                }
                @media (max-width:1300px){
                    padding: 0px;
                }
                .rtprod[type="checkbox"]{
                    display: none;
                }
                // .rtprod:checked ~ label .fa-chevron-down{                    
                //     display: block;
                // }
                // .rtprod:checked ~ label .fa-chevron-right{                    
                //     display: none;
                // }            
                    
                h5{
                    font-size: 30px;      
                    @media (max-width:1300px){
                        font-size: 23px;                       
                    }    
                    @media (max-width:500px){
                        font-size: 18px;
                    }          
                }
                .answer{
                    padding: 0 10px;                    
                    .body{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // border: solid 1px;
                        padding: 0;
                        p{
                            font-size: 16px;
                            // border: solid 1px;
                            @media (max-width:1300px){
                                font-size: 16px;
                            }
                            @media (max-width:500px){
                                font-size: 13px;
                            }
                        }
                        h6{
                            font-size: 14px;
                            font-weight: 500;
                            @media (max-width:1300px){
                                font-size: 16px;
                            }
                            @media (max-width:500px){
                                font-size: 13px;
                                margin-top: -5px;
                            }
                        }
                        .delet-checkbox, .eye-checkbox[type="checkbox"]{
                            display: none;
                        }
                        .delet-prod{
                            width: 20px;
                            height: 20px;
                            padding-bottom: 2px;                        
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: red;
                            color: white;
                            font-size: 17px;
                            border-radius: 50%;
                            cursor: pointer;
                            @media (max-width:1300px){
                                font-size: 16px;
                                width: 18px;
                                height: 18px;
                                margin-top: -5px;
                            }
                            @media (max-width:500px){
                                font-size: 14px;
                                width: 16px;
                                height: 16px;
                                margin-top: -10px;
                            }
                        }
                        .delet-checkbox[type="checkbox"]:checked ~ .delet-prod{                            
                            background-color: rgb(224, 224, 224);
                            color: black;
                        }                        
                        .eye-prod{
                            width: 24px;
                            height: 18px;
                            padding-bottom: 1px;                       
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: black;
                            color: black;
                            // font-size: 17px;
                            border-radius: 50%;
                            cursor: pointer;
                            .visibleoff{
                                display: none;
                                color: white;
                            }
                            .visible{
                                color: white;
                            }
                            @media (max-width:1300px){
                                // font-size: 16px;
                                width: 22px;
                                height: 16px;
                                margin-top: -5px;
                                padding-bottom: 0px;
                            }
                            @media (max-width:500px){
                                font-size: 12px;
                                width: 22px;
                                height: 16px;
                                margin-top: -10px;
                                padding-bottom: 0px;
                            }
                        }
                        .eye-checkbox[type="checkbox"]:checked ~ .eye-prod .visibleoff {
                            display: block;
                        }
                        .eye-checkbox[type="checkbox"]:checked ~ .eye-prod .visible {
                            display: none;
                        }
                    }
                    .click, .payme, .cash{
                        border-radius: 0;
                        border: solid 1px black;
                        color: black;
                        padding: 10px 30px;
                        &:hover{
                            box-shadow: 0 0 10px 2px rgb(187, 187, 187);
                        }
                        // &:focus{
                        //     background-color: black;
                        //     color: white;
                        // }
                        @media (max-width:1300px){
                            padding: 5px 20px;
                        }
                        @media (max-width:500px){
                            font-size: 14px;
                            padding: 5px 15px;
                        }                           
                    }
                    .location{
                        border-radius: 0;
                        border: solid 1px black;
                        color: black;
                        padding: 10px 30px;
                        background-color: white;
                        &:hover{
                            box-shadow: 0 0 10px 2px rgb(187, 187, 187);
                        }
                        &:focus{
                            background-color: black;
                            color: white;
                        }
                        @media (max-width:1300px){
                            padding: 5px 20px;
                        }
                        @media (max-width:500px){
                            font-size: 14px;
                            padding: 5px 15px;
                        }  
                    }
                    .form-control.red-line{
                        border: solid 1px red; 
                    }
                }
                .fa-chevron-down{                    
                    color: black;   
                    cursor: pointer; 
                    font-size: 20px;  
                    @media (max-width:1300px){
                        font-size: 16px;
                    }                                              
                }  
                .fa-chevron-right{                    
                    color: black;   
                    cursor: pointer; 
                    font-size: 20px;  
                    @media (max-width:1300px){
                        font-size: 16px;
                    }                                              
                }                                              
            }
            .delivery{
                margin-top: 20px;
                .border-dark{
                    padding: 8px 10px;
                    @media (max-width:1300px){
                        padding: 6px 6px;
                        font-size: 18px;
                    }
                    @media (max-width:500px){
                        font-size: 16px;                      
                    } 
                }
            }
        }
    }
    .fa-times{
        position: absolute;
        color: black;
        top: 30px;
        right: 30px;
        font-size: 28px;
        cursor: pointer;
        @media (max-width:600px){
            top: 20px;
            right: 20px;
            font-size: 20px;
        }
    }
    .info-user{
        margin-top: 50px;
        padding: 0 10px;
        h5{
            font-size: 30px;      
            @media (max-width:1300px){
                font-size: 23px;                       
            }    
            @media (max-width:500px){
                font-size: 18px;
            }          
        }
        .fa-chevron-down{                    
            color: black;   
            cursor: pointer; 
            font-size: 20px;  
            @media (max-width:1300px){
                font-size: 16px;
            }                                              
        }  
        .col-md-6{
            margin-top: 10px;
            .red-line{
                border: solid 1px red;              
            }
        }
    }
    .btns{
        // border: solid 1px;
        display: flex;  
        margin-top: 50px;      
        align-items: center;
        justify-content: center;
        @media (max-width:767px){
            margin-top: 70px;   
        }
        .btn{
            padding: 10px 60px;     
            border-radius: 0;
            color: white;
            font-size: 18px;
            background-color: darkcyan;      
            &:hover{
                background-color: white;
                color: darkcyan;
                border: solid 2px darkcyan;
            }   
            @media (max-width:500px){
                font-size: 16px;
                padding: 10px 40px;
            }       
        }
    }
}
.buy-ok{
    position: fixed;
    top: -150%;
    transition: 0.4s;
    z-index: 500006;
    background-color: darkcyan; 
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 150px;
    border-radius: 10px;
    @media (max-width:500px){
        width: 90%;
        left: 5%;
    }
    @media (max-width:450px){
        padding: 70px 30px;
    }
    h1{
        color: white;
        text-align: center;
    }
    .fa-times{
        position: absolute;
        color: white;
        top: 30px;
        left: 30px;
        font-size: 28px;
        cursor: pointer;
        @media (max-width:450px){
            top: 10px;
            left: 20px;
        }
    }
    .btn{
        padding: 10px 40px;
        margin-top: 50px;
        border: solid 2px white;
        border-radius: 15px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        &:hover{
            background-color: white;
            color: darkcyan;
        }
        @media (max-width:500px){
            margin-top: 20px;            
        }
    }
}
.contrast{
    background-color: rgba(0, 0, 0, 0.699);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500004;
}
.show-modal{
     top: 10%;
}
.contrast-buy{
    background-color: rgba(0, 0, 0, 0.699);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500003;
    // display: none;
}
.loader{
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 77777777777;
    transition: 0.3s;
    .load{
        border-radius: 50%;
        width: 150px;
        height: 150px;
        border: solid 10px white;
        border-left-color: rgb(1, 2, 58);
        border-top-color: rgb(1, 2, 58);
        animation-name: loader;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes loader {
    100%{
        transform: rotate(360deg);
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    list-style: none;
    text-decoration: none;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
 
  
  html{
    scroll-behavior: smooth;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
  
  @import "./components/Slider/Slider.scss";
  
  
  body {
    font-family: 'Open Sans', sans-serif;
  }