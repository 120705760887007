.about{
    padding: 0px 0 70px 0;
    margin-top: 100px;


    &__name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 73.9459px;
        line-height: 120%;
        color: #000;
        margin-top: 30px;
        text-align: center;
        letter-spacing: -0.03em;
    }
    &__img{
        border-radius: 9.72973px;
        margin:0 auto;
    }

    &__date{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #000;
        margin-bottom: 30px;
    }

    &__message{
        background: #000000;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: fixed;
        z-index: 1;
        top: 78%;
        left: 90%;
        cursor: pointer;
    }
    
   
    &__picture{
         margin-top: 5px;
    }

    &__text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 17.5135px;
        line-height: 150%;
        color: #000;
        width: 600px;
        margin: 20px auto;
    }

    &__page{
        text-align: center;
    }
}

.about .about__date{
    text-align: center;
}



@media (max-width:1350px) {
    
    .container{
        width: 1160px;
    }
}

@media (max-width:1160px) {
    
    .container{
        width: 1000px;
    }

    .about{

        &__img{
            width: 900px;
        }
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }
}

@media (max-width:920px) {
    
    .container{
        width: 800px;
    }
    .about{

        &__img{
            width: 780px;
        }
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }

    .about{

        &__img{
            width: 680px;
        }
    }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }
    .about{

        &__img{
            width: 590px;
        }

        &__text{
            font-size: 14px;
            line-height: 120%;
            width: 500px;
            margin: 20px auto;
        }
    }
}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .about{

        &__img{
            width: 490px;
        }

        &__text{
            font-size: 12px;
            line-height: 120%;
            width: 450px;
            margin: 20px auto;
        }

        &__message{
            width: 55px;
            height: 55px;
        }
        &__picture{
            width: 40px;
            height: 40px;
        }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .about{

        &__img{
            width: 100%;
            object-fit: cover;
        }

        &__text{
            font-size: 12px;
            line-height: 120%;
            width: 380px;
            margin: 20px auto;
        }

        &__message{
            top: 78%;
            left: 85%;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 300px;
    }

    .about{

        &__img{
            width: 100%;
        }

        &__name{
            font-size: 30px;
        }

        &__text{
            font-size: 10px;
            line-height: 120%;
            width: 280px;
            margin: 20px auto;
        }
    }
}