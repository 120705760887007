.blog{
    padding: 40px 0;
    margin-top: 60px;

    &__name{
        font-size: 65px;
        line-height: 70px;
        font-weight: 700;
        text-align: center;
    }

    &__img{
        width: 800px;
        height: 850px;
        margin: 50px 0;
        border-radius: 9.72973px;
    }

    &__title{
        text-align: center;
    }

    &__text{
        font-size: 18px;
        width: 600px;
        line-height: 20px;
        margin: 0 auto;
        margin-top: 50px;
    }

}
.icons{
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 150px;
    @media (max-width:1220px) {
        padding-left: 130px;
    }
    @media (max-width:1199px) {
        padding-left: 70px;
    }
    @media (max-width:700px) {
        padding-left: 0px;
    }
    @media (max-width:520px) {
        margin-bottom: 20px;
    }
    i,p{
        padding: 0;
        margin: 0 7px;
        font-size: 20px;
        font-weight: 600;
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }

    .blog{

        &__img{
            width: 750px;
            height: 800px;
            margin-left: -26px;
        }
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }

    .blog{

        &__img{
            width: 680px;
            margin-left: 0;
        }
    }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }

    .blog{

        &__img{
            width: 550px;
            height: 600px;
            margin-left: -10px;
        }
    }
}

@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .blog{
        
        &__img{
            width: 460px;
            margin-left: 0;
        }

        &__text{
            width: 480px;
        }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .blog{

        &__text{
            width: 350px;
            margin: 0 auto;
            font-size: 14px;
        }

        &__name{
            font-size: 50px;
        }

        &__img{
            width: 360px;
        }
    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .blog{

        &__img{
            width: 230px;
            height: 350px;
        }

        &__text{
            width: 300px;
            font-size: 13px;
        }
    }
}