.client{
    padding: 40px 0;

    &__list{
        list-style: none;
        background: #FFFFFF;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
        border-radius: 10.0244px;
        width: 188px;
        height: 250px;
        margin: 20px 40px;
        transition: 0.3s;        
        &:hover{
            transform: scale(1.1);
        }
    }

    &__name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        text-align: center;
        font-size: 32.8495px;
        line-height: 125%;
        color: #000;
    }

    &__item{
        margin: 10px 0;
    }
    .desc{
        text-align: center;
    }



    &__img{
        margin-top: -34px;
    }

    &__names{
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 10.0244px;
        line-height: 125%;
        margin-top: -34px;
        margin-left: 25px;
    }

    &__items{
        width: 60px;
        margin-left: 27px;
        height: 60px;
        background-color: #FFFFFF;
    }

    &__item:nth-child(3){
        margin-top: -20px;
        margin-left: 3px;
    }

    
    &__star{
        visibility: hidden;
        font-size: 20px;
        margin: 0 4.5px;
        cursor: pointer;

        &::before{
            visibility: visible;
        }

        &:checked::before{
            content: "\2605";
            position: absolute;
        }
    }

    &__text{
        font-style: normal;
        font-weight: 300;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 10.0244px;
        line-height: 125%;
        color: #000;
        width: 160px;
        height: 70px;
        margin-top: 30px;
        margin-left: -25px;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
    }

    &__page{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title{
        list-style: none;
        display: flex;
        justify-content: center;        
        align-items: center;
        @media (max-width:520px) {        
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }
    }

    &__titles{
        margin: 10px 45px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__box{
        padding: 50px 0;
    }

    .col-2{
        padding: 10px;
        min-height: 200px;
        position: relative;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            transform: scale(1.1);
        }
        @media (max-width:520px) {
            min-height: 130px;
            width: 33%;
            padding: 10px;            
        }
        a{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                position: absolute;
                width: 80%;
                max-height: 100%;     
                @media (max-width:520px) {
                    width: 80%;           
                }           
            }
        }
    }

    // &__pic{
    //     width: 163px;
    //     height: 43px;
    // }

    // &__picture{
    //     width: 215px;
    //     height: 41px;
    // }
    // &__pictures{
    //     width: 110px;
    //     height: 110px;
    // }
}

@media (max-width:1350px) {
    
    .container{
        width: 1160px;
    }
}

@media (max-width:1160px) {
    
    .container{
        width: 1000px;
    }

    .client{

        &__list{
            margin: 20px 30px;
        }

        &__titles{
            margin: 10px 40px;
        }
    }
}

@media (max-width:1020px) {
    
    .container{
        width: 900px;
    }

    .client{

        &__list{
            margin: 20px 20px;
        }

        &__titles{
            margin: 10px 30px;
        }
    }
}

@media (max-width:920px) {
    
    .container{
        width: 800px;
    }

    
    .client{

        &__list{
            margin: 20px 10px;
        }

        &__names{
            margin-left: 0;
        }

        &__item:nth-child(3){
            margin-left: 5px;
        }
        &__items{
            margin-left: 22px;
        }

        &__titles{
            margin: 10px 15px;
        }
    }
}

@media (max-width:820px) {
    
    .container{
        width: 700px;
    }

    .client{

        &__list{
            width: 168px;
            height: 200px;
            margin: 20px 20px;
        }

        &__items{
            margin-left: 28px;
        }

        &__text{
            font-size: 8px;
            width: 140px;
            margin-left: -15px;
        }

        &__page{
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

@media (max-width:720px) {
    
    .container{
        width: 600px;
    }

    .client{

        &__titles{
            margin: 10px 10px;
        }

      &__pics{
        width: 200px;
      }
    }
}



@media (max-width:620px) {
    
    .container{
        width: 500px;
    }

    .client{
        &__titles{
            margin: 10px 10px;
        }

      &__pics{
        width: 200px;
      }
    }
}

@media (max-width:520px) {
    
    .container{
        width: 400px;
    }

    .client{

        &__names{
            font-size: 14px;
        }


        &__list{
            width: 160px;
            height: 180px;
            margin: 20px 10px;
        }

        &__picture{
            width: 150px;
        }

        &__pics{
            width: 150px;
        }

        &__text{
            font-size: 9px;
            margin-left: -15px;
        }

        &__items{
            margin-left: 20px;
        }
        &__names{
            margin-left: 5px;
        }

    }
}

@media (max-width:420px) {
    
    .container{
        width: 330px;
    }

    .client{

      

        &__titles{
            margin: 20px 5px;
        }
        &__list{
            width: 130px;
            height: 160px;
            margin: 20px 9px;            
        }

        &__text{
            font-size: 8.48595px;
            line-height: 125%;
            width: 113px;
            margin-top: 20px;
            margin-left: -20px;
        }

        &__names{
            font-size: 12px;
            margin-left: -10px;
        }

        &__star{
            font-size: 15px;
            margin-left: -5px;
        }
        &__items{
            margin-left: 8px;
        }

        &__pic{
            width: 100px;
            // height: 30px;
        }

        &__picture{
            width: 120px;
            // height: 35px;
        }

        &__pics{
            width: 120px;
        }

        &__pictures{
            height: 100px;
        }

        &__img{
            margin-left: -5px;
        }
    }
}

.client{
    .wrapper{
        padding: 50px 15vw;
        @media (max-width:1200px) {
            padding: 50px 10vw;
        }
        @media (max-width:1000px) {
            padding: 0px 7vw;
        }
        .client-page{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @media (max-width:767px) {
                display: flex;
                // grid-template-columns: repeat(2, 2fr);
                margin-top: 20px;
            }
            .person{
                margin-top: 20px;
                padding: 30px;
                height: auto;
                @media (max-width:1400px) {
                    padding: 25px;
                }
                @media (max-width:1000px) {
                    padding: 25px 15px;
                }
                @media (max-width:767px) {
                    width: 50%;
                    margin-top: 10px;
                }
                @media (max-width:450px) {
                    width: 50%;
                    padding: 20px 10px;
                }
                .body{
                    height: 100%;
                    background-color: white;
                    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
                    border-radius: 10.0244px;
                    padding: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    cursor: pointer;
                    transition: 0.2s linear;
                    &:hover{
                        transform: scale(1.1);
                    }
                    .person-img{
                        position: absolute;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        top: -30px;
                        filter: drop-shadow(0px 4.00977px 11.0269px rgba(0, 0, 0, 0.3));
                        @media (max-width:1200px) {
                            width: 50px;
                            height: 50px;
                            top: -25px;
                        }
                    }
                    .name{
                        margin-top: 30px;
                        font-weight: 700;
                        text-align: center;
                        @media (max-width:1400px) {
                            font-size: 13px;
                        }
                        @media (max-width:1200px) {
                            margin-top: 20px;
                        }
                        @media (max-width:450px) {
                            font-size: 10px;
                        }
                    }
                    .stars{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                        @media (max-width:1400px) {
                            margin-top: 5px;
                        }
                        @media (max-width:450px) {
                            margin-top: 0px;
                        }
                        .fa-star{
                            margin: 0 5px;
                            @media (max-width:1400px) {
                                font-size: 13px;
                            }
                            @media (max-width:450px) {
                                font-size: 10px;
                                margin: 0 3px;
                            }
                        }
                    }
                    .desc{
                        margin-top: 50px;
                        text-align: left;
                        @media (max-width:1400px) {
                            font-size: 13px;
                            margin-top: 30px;
                        }
                        @media (max-width:450px) {
                            font-size: 10px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}
